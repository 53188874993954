import * as React from 'react';
import styles from './dialog.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { DIALOG_ITEM_INFO_TYPE, IDialogData } from '~/interfaces';
import DialogItemInfo from '~/Components/Dialog/DialogItemInfo';

export const DIALOG_ITEM_INFO: DIALOG_ITEM_INFO_TYPE = 'DIALOG_ITEM_INFO';

interface IState {
    dialogData: IDialogData;
}

const stateSelector = (state: State): IState => {
    return {
        dialogData: state.ReducerCategory.dialog,
    };
};

const DialogManager = () => {
    const dialogData = useSelector<State, IState>(stateSelector, shallowEqual);

    if (!dialogData.dialogData) {
        return null;
    }

    const getDialogContent = () => {
        switch (dialogData.dialogData.popupName) {
            case DIALOG_ITEM_INFO:
                return <DialogItemInfo data={dialogData.dialogData} />;

            default:
                return null;
        }
    };

    const content = getDialogContent();
    if (!content) {
        return null;
    }

    return <div className={styles.wrapper}>{content}</div>;
};

export default DialogManager;
