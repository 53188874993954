import * as React from 'react';
import styles from './RouteContent.scss';
import Routing from './Routing';

const RouteContent = () => {
    return (
        <section className={styles.section}>
            <div id={'router-main-app'}>
                <Routing />
            </div>
        </section>
    );
};

export default RouteContent;
