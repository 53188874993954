const GLOSSARY_URL = window.catalogue.urls.vortex + 'api/graphql/glossary/';
const INVENTORY_URL = window.catalogue.urls.vortex + 'api/graphql/inventory/';
const LOCAL_GLOSSARY_URL = 'http://vortex.portal.loc:8080/api/graphql/glossary/';
const LOCAL_INVENTORY_URL = 'http://vortex.portal.loc:8080/api/graphql/inventory/';

interface IOptions {
    host?: string;
    query?: string;
}

export const get = (options: IOptions = {}) => {
    const host = options.host || window.catalogue.urls.vortex;
    const url = `${host}?query=${encodeURIComponent(options.query)}`;

    return fetch(url, {
        method: 'GET',
    }).then((response) => response.json());
};

export const post = (options: IOptions) => {
    const url = options.host || window.catalogue.urls.vortex;

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            query: options.query,
            variables: null,
            operationName: null,
        }),
    }).then((response) => response.json());
};

export const loadDataFromGlossary = (options: IOptions = {}) => {
    options.host = options.host || GLOSSARY_URL;
    return post(options);
};

export const loadDataFromInventory = (options: IOptions = {}) => {
    options.host = options.host || INVENTORY_URL;

    // return {
    //     data: {
    //         inventory: {
    //             activeDoll: {
    //                 icons: {
    //                     default: "//glossary-wows-global.gcdn.co/icons/dog_tags/small/PCNA040_4a349210ca63d333a8d323f2d4d6824c6855fb693afeea561f3c550ed7824fe4.png",
    //                     large: "//glossary-wows-global.gcdn.co/icons/dog_tags/large/PCNA040_e5a142a7668304d1e73a804f08117a7c8f7447bee6cf2c1708e6dd57d66fabb8.png",
    //                     medium: "//glossary-wows-global.gcdn.co/icons/dog_tags/medium/PCNA040_5d00b0604d94daca19ac0361926528fffd774abc18faf138c192e2bab37a0b8c.png",
    //                     small: "//glossary-wows-global.gcdn.co/icons/dog_tags/small/PCNA040_4a349210ca63d333a8d323f2d4d6824c6855fb693afeea561f3c550ed7824fe4.png",
    //                 },
    //                 id: "4253010864",
    //                 symbol: {
    //                     id: "4146023344",
    //                     icons: {
    //                         default: "//glossary-wows-global.gcdn.co/icons/dog_tags/small/PCNB142_f450206928cb0f52197bacbf89225e21667d13d9b5a94496676d1fac5ebd543e.png",
    //                         large: "//glossary-wows-global.gcdn.co/icons/dog_tags/large/PCNB142_1c64b1d4ab88edcb72577b778ab6fb5ea4cb18ad3d29126573a4c19ea9f39bba.png",
    //                         medium: "//glossary-wows-global.gcdn.co/icons/dog_tags/medium/PCNB142_f772908c5b23e1d97d3e7f94715ec6d88e5f7bde35a32971bc6860a21533a9ae.png",
    //                         small: "//glossary-wows-global.gcdn.co/icons/dog_tags/small/PCNB142_f450206928cb0f52197bacbf89225e21667d13d9b5a94496676d1fac5ebd543e.png",
    //                     }
    //                 },
    //             },
    //             storage: [
    //                 {id: "3454414128"}
    //             ],
    //             hold: [
    //                 {
    //                     id: "4186468272",
    //                     vehicles: [{vehicleId: "4293801680", count: 1}]
    //                 }
    //             ],
    //             crew: [
    //                 {id: "4293044176"},
    //                 {id: "4293043408"},
    //                 {id: "4293044016"},
    //                 {id: "4293043920"},
    //                 {id: "4293044048"},
    //                 {id: "4293043664"},
    //                 {id: "4293043664"}
    //             ],
    //             dogTag: [
    //                 "4293905328",
    //                 "4288662448",
    //                 "4283419568",
    //                 "4278176688",
    //                 "4272933808",
    //                 "4293872560",
    //                 "4292823984",
    //                 "4291775408",
    //                 "4290726832",
    //                 "4215229360",
    //                 "4031728560",
    //                 "4032777136",
    //                 "4033825712",
    //                 "4034874288",
    //                 "4035922864",
    //                 "4036971440",
    //                 "4038020016",
    //                 "4022291376",
    //                 "4023339952",
    //                 "4024388528",
    //                 "4025437104",
    //                 "4009708464",
    //                 "4012854192",
    //                 "4013902768",
    //                 "4014951344",
    //                 "4015999920",
    //                 "4251962288",
    //                 "4146023344",
    //                 "4253010864"
    //             ],
    //             vehicles: ["3760109552", "3751687376", "3762205904", "4075730384", "4293834192", "4181669616", "4292785616", "3762173232", "4293801680", "3763222224", "4078876624", "4184815408", "3751753168", "3756963280", "4188976432", "3765352144", "3560912592", "4079925200", "4188976336", "4076778960", "4188976880", "3750704848", "4255037136", "3763255280", "3765352400", "3764270896", "3767449584", "4293834544", "3667867440", "3659445712", "4185863920", "4292818736", "3766433072", "3760174896", "4256085712", "3763221872", "4180555056", "4076745936", "4181603632", "3764303600", "3765319664", "3763254608", "3764303312", "4182718192", "3751720944", "3764270928", "4182652208", "3765384656", "4188977104", "3764303696", "4183700784", "3762206544", "4183766768", "4184749360", "3542005456", "3753818064", "3764303664", "3762173136", "3764303216", "4180621040", "3655251952", "3763255024", "4293834736", "3761190704", "4188976592", "3730781648", "3752802288", "3762239280", "3765352176", "3769513264", "4184815344", "3751753552", "4188976976", "3763288048", "4076779472", "3764303056", "3765319120", "3754866128", "3761190640"]
    //         }
    //     }
    // }

    return post(options);
};
