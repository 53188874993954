import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    permoflages (lang: "{{ languageCode }}", isCatalogue: true) {
        id
        title
        description
        tags
        typeName
        icons {
            default
            small
            large
        }
        vehicles {
            isNative,
            vehicle {
                id,
                title,
                titleShort,
                level, 
                isPremium,
                isSpecial,
                tags
                nation {
                    name,
                    icons {
                        small
                        tiny
                        default
                    }
                }
                type {
                    name,
                    icons {
                        default
                        special
                        normal
                        elite
                        premium
                    }
                }
            }
        }
    }
}
`;
