import * as React from 'react';
import styles from '~/Components/Profile/Profile.scss';
import { isAuthorizedUser } from '~/config/user';
import classNames from 'classnames';
import { t } from '~/helpers/localization';
import { IProgressSVG } from '~/Components/Profile/SvgRoom';

const CamoProgressBar = React.forwardRef((props: IProgressSVG, ref: any) => {
    const rectRef = React.useRef<SVGRectElement>();

    React.useEffect(() => {
        if (!rectRef.current) {
            return;
        }

        const pathVehicleLength = rectRef.current.width.baseVal.value;
        const svgVehiclePathTo = Math.floor((pathVehicleLength * props.progressCollected.getPercentProgressPermoflage()) / 100);

        rectRef.current.style.width = `${svgVehiclePathTo}px`;
    }, []);

    if (!isAuthorizedUser()) {
        return null;
    }

    return (
        <svg width="2500" height="1406" viewBox="0 0 2500 1406" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.svgProgress} ref={ref}>
            <g filter="url(#permoflagesProgressG)">
                <path d="M1223.34 718L1648 731V804H1227.84L1209 763L1223.34 718Z" fill="black" fillOpacity="0.4" />
            </g>
            <path opacity="0.5" d="M1224.01 760.5L1223.5 760.488V761V771V771.488L1223.99 771.5L1637.99 781.5L1638.5 781.512V781V771V770.512L1638.01 770.5L1224.01 760.5Z" fill="black" stroke="white" />
            <mask id="permoflagesProgressMask" mask-type="alpha" maskUnits="userSpaceOnUse" x="1224" y="761" width="414" height="20">
                <path d="M1224 761L1638 771V781L1224 771V761Z" fill="#FFCC00" />
            </mask>
            <g mask="url(#permoflagesProgressMask)">
                <rect x="1223" y="729" width="415" height="52" fill="#FFCC00" ref={rectRef} />
            </g>
            <defs>
                <filter id="permoflagesProgressG" x="1169" y="678" width="519" height="166" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="20" result="effect1_foregroundBlur" />
                </filter>
            </defs>
            <text x={'1312'} y={'726'} className={classNames(styles.textProgressBar, styles.textProgressBarPermoflages)}>
                {t('Камуфляжи')} {props.progressCollected.getAvailablePermoflagesCount()}/{props.progressCollected.getTotalPermoflagesCount()}
            </text>
        </svg>
    );
});

export default CamoProgressBar;
