import * as React from 'react';
import classNames from 'classnames';
import styles from './Search.scss';
import { t } from '~/helpers/localization';
import { ICategories } from '~/interfaces';
import { useDispatch } from 'react-redux';
import { search, resetSearch } from '~/Actions/ActionCategory';
import { resetFilters } from '~/Actions/ActionFilter';
import { playButtonClickSound, playInputSound } from '~/helpers/audioApi';

interface IProps {
    onKeyDown?: any;
    resetSearch?: any;
    category: ICategories;
}

const Search = (props: IProps) => {
    const dispatch = useDispatch();
    const [state, setState] = React.useState<{
        value: string;
        isVisibleResetSearch: boolean;
    }>({
        value: '',
        isVisibleResetSearch: false,
    });

    const onChange = (event: React.KeyboardEvent) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const isVisibleResetSearch = !!value.length;

        setState({ value, isVisibleResetSearch });
    };

    const onKeyUp = (event: React.KeyboardEvent) => {
        const target = event.target as HTMLInputElement;
        dispatch(resetFilters(props.category));
        dispatch(search(target.value, props.category));
        props.onKeyDown?.(target.value, event);
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key !== 'Unidentified') {
            playInputSound();
        }
    };

    const _resetSearch = () => {
        playButtonClickSound();

        setState({ value: '', isVisibleResetSearch: false });
        dispatch(resetSearch());
        props.resetSearch?.();
    };

    const classNameSearchWrap = classNames(styles.searchWrap);

    return (
        <div className={classNameSearchWrap}>
            <div className={styles.searchInputWrap}>
                {state.isVisibleResetSearch && <span className={styles.resetSearch} onClick={_resetSearch.bind(this)} />}
                <input
                    value={state.value}
                    placeholder={t('Поиск по названию')}
                    type="text"
                    className={styles.searchInput}
                    onKeyUp={onKeyUp.bind(this)}
                    onKeyDown={onKeyDown.bind(this)}
                    onChange={onChange.bind(this)}
                />
            </div>
        </div>
    );
};

export default Search;
