import * as React from 'react';
import styles from '~/Components/Profile/Profile.scss';
import { isAuthorizedUser } from '~/config/user';
import classNames from 'classnames';
import { t } from '~/helpers/localization';
import { IProgressSVG } from '~/Components/Profile/SvgRoom';

const CrewsProgressBar = React.forwardRef((props: IProgressSVG, ref: any) => {
    const rectRef = React.useRef<SVGRectElement>();

    React.useEffect(() => {
        if (!rectRef.current) {
            return;
        }

        const pathVehicleLength = rectRef.current.width.baseVal.value;
        const svgVehiclePathTo = Math.floor((pathVehicleLength * props.progressCollected.getPercentProgressCrew()) / 100);

        rectRef.current.style.width = `${svgVehiclePathTo}px`;
    }, []);

    if (!isAuthorizedUser()) {
        return null;
    }

    return (
        <svg width="2500" height="1406" viewBox="0 0 2500 1406" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.svgProgress} ref={ref}>
            <g filter="url(#crewProgressG)">
                <path d="M1853.97 365L2323 378V419L1859.91 451L1835 410L1853.97 365Z" fill="black" fillOpacity="0.3" />
            </g>
            <path
                opacity="0.5"
                d="M1852.96 424.501L1852.5 424.534V425V435V435.537L1853.04 435.499L2304.04 403.499L2304.5 403.466V403V393V392.463L2303.96 392.501L1852.96 424.501Z"
                fill="black"
                stroke="white"
            />
            <mask id="crewProgressMask" mask-type="alpha" maskUnits="userSpaceOnUse" x="1853" y="393" width="451" height="42">
                <path d="M1853 425L2304 393V403L1853 435V425Z" fill="#FFCC00" />
            </mask>
            <g mask="url(#crewProgressMask)">
                <rect x="1852" y="393" width="452" height="42" fill="#FFCC00" ref={rectRef} />
            </g>
            <defs>
                <filter id="crewProgressG" x="1795" y="325" width="568" height="166" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="20" result="effect1_foregroundBlur" />
                </filter>
            </defs>
            <text x={'1867'} y={'540'} className={classNames(styles.textProgressBar, styles.textProgressBarCrews)}>
                {t('Командиры')} {props.progressCollected.getAvailableCrewCount()}/{props.progressCollected.getTotalCrewsCount()}
            </text>
        </svg>
    );
});

export default CrewsProgressBar;
