import styles from './Item.scss';
import { ICategories, IInventoryList, IInventoryState } from '~/interfaces';
import { Item } from '~/interfaces';
import classNames from 'classnames';
import { Crew, Doll, Permoflages } from '~/vortex';
import { INVENTORY_KEY_BY_CATEGORY } from '~/helpers/consts';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { isAuthorizedUser } from '~/config/user';
import { CREWS_CATEGORY, DOLLS_CATEGORY, ENSIGNS_CATEGORY, PERMOFLAGES_CATEGORY, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { PresentationStyles, items } from '@wg/wows-entities/const';
import { isInGameBrowser } from '~/helpers/utils';
import { playCrewRandomVOSample } from '@wg/web2clientapi/sound';
import ReactDOM from 'react-dom';
import { Message } from '@wg/wows-react-uikit';
import { t } from '~/helpers/localization';
import LazyElement from '../LazyElement/LazyElement';
import { ReactNode, RefObject, memo } from 'react';
import { IVCrew, IVDoll, IVEnsign, IVPermoflages, IVVehicle } from '@wg/wows-entities/types/vortex';
import { SlotFunction } from '@wg/wows-entities/types/registerElement';

interface IItem {
    category: ICategories;
    item: Item;
    parentRef: RefObject<HTMLDivElement>;
}

const isItemOwned = (item: Item, category: ICategories, inventory: IInventoryState): boolean => {
    if (!isAuthorizedUser()) {
        return true;
    }

    const inventoryKey = INVENTORY_KEY_BY_CATEGORY[category] as IInventoryList;
    const items = (inventory[inventoryKey] as string[]) || [];

    return items.includes(item.id);
};

interface IState {
    inventory: IInventoryState;
}

const stateSelector = (state: State): IState => {
    return {
        inventory: state.ReducerApp.inventory,
    };
};

const Item = ({ category, item, parentRef }: IItem) => {
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    let type = null;
    let shipId = null;
    let crewId = null;
    const isOwned = isItemOwned(item, category, appState.inventory);
    let itemData;

    const tooltipContentAfterHeader = [
        <Message type={isOwned ? 'done' : 'error'} style={null} key={`message_block_${item.id}`}>
                {isOwned ? t('Получено') : t('Не получено')}
        </Message>
    ];

    const tooltipContentBeforeHeader: ReactNode[] = [];

    if (category === CREWS_CATEGORY) {
        type = items.CREWS;
        itemData = item as IVCrew;

        if (itemData.nation?.icons?.tiny) {
            tooltipContentBeforeHeader.unshift(
                <div className={styles.nationWrapper} key={`nation_block_${item.id}`}>
                    <img src={itemData.nation?.icons?.tiny} className={styles.nationIcon} />
                    <span>{itemData.nation.title}</span>
                </div>
            );
        }
    } else if (category === DOLLS_CATEGORY) {
        type = (item as Doll).type;
        if (type !== items.BACKGROUND) {
            itemData = item as IVDoll;
        }
    } else if (category === ENSIGNS_CATEGORY) {
        type = items.ENSIGNS;
        itemData = item as IVEnsign;
    } else if (category === VEHICLE_CATEGORY) {
        type = items.VEHICLES;
        itemData = item as IVVehicle;
    } else if (category === PERMOFLAGES_CATEGORY) {
        const camo = item as Permoflages;
        itemData = item as IVPermoflages;
        const camoVehicle = camo.vehicles?.[0];
        type = items.PERMOFLAGES;
        if (camoVehicle) {
            shipId = camoVehicle.vehicle.id;
        }
    }

    const classNameItem = classNames(styles.item, {
        [styles.notOwn]: !isOwned,
        [styles.isUnique]: item.isUniqueItem,
        [styles.isRare]: item.isRareItem,
    });

    const isVoicePreviewEnabled = isInGameBrowser && type === items.CREWS && !!+item.id && (item as Crew).hasSampleVo;

    const previewCrewVoice = () => {
        if (!isVoicePreviewEnabled) {
            return;
        }
        const crewId = +item.id;
        playCrewRandomVOSample(crewId);
    };

    const customisationCallbacks: {
        afterHeaderTooltipSlot?: SlotFunction;
        beforeFooterTooltipSlot?: SlotFunction;
    } = {
        afterHeaderTooltipSlot(node) {
            ReactDOM.render(tooltipContentAfterHeader, node);
        },
    };

    if (!!tooltipContentBeforeHeader.length) {
        customisationCallbacks['beforeFooterTooltipSlot'] = (node) => {
            ReactDOM.render(tooltipContentBeforeHeader as JSX.Element[], node);
        };
    }

    return (
        <LazyElement className={classNameItem} parentRef={parentRef}>
            <WoWSEntity 
                type={type}
                id={item.id}
                itemData={itemData}
                presentation={{
                    style: PresentationStyles.CARD,
                    withTooltip: true,
                    isAvailableCrewVoicePrevew: isVoicePreviewEnabled,
                    renderWithoutFade: true,
                }}
                customisation={{
                    shipId,
                    crewId,
                    ...customisationCallbacks,
                }}
                onClick={{
                    onCrewVoicePreview: previewCrewVoice,
                }}
            />
        </LazyElement>
    );
};

export default memo(Item);
