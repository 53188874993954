import * as React from 'react';
import classNames from 'classnames';
import styles from './MainProgress.scss';
import { t } from '~/helpers/localization';
import Number from '../Number/Number';
import { isAuthorizedUser } from '~/config/user';

interface IProps {
    specialAwardsCount?: number;
    uniqueAwardsCount?: number;
    totalCount: number;
    availableCount: number;
    percentProgressCollected?: number;
}

const MainProgress = (props: IProps) => {
    const { percentProgressCollected, totalCount, availableCount } = props;
    const step = Math.max(Math.floor(percentProgressCollected / 10), 1);
    const imageStep = step % 2 === 0 ? step / 2 : (step - 1) / 2;
    const helmClassName = classNames(styles.helm, styles[`helm${Math.max(imageStep, 1)}`]);

    return (
        <div className={styles.wrap}>
            <div className={styles.column}>
                <div className={helmClassName} />
                {isAuthorizedUser() && (
                    <React.Fragment>
                        <div className={styles.allInfoCount}>
                            <Number value={availableCount} />
                            <span>/</span>
                            <Number value={totalCount} />
                        </div>
                        <div className={styles.text}>{t('Собрано всего')}</div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default MainProgress;
