import * as React from 'react';
import styles from '~/Components/Profile/Profile.scss';
import { isAuthorizedUser } from '~/config/user';
import classNames from 'classnames';
import { t } from '~/helpers/localization';
import { IProgressSVG } from '~/Components/Profile/SvgRoom';

const EnsignsProgressBar = React.forwardRef((props: IProgressSVG, ref: any) => {
    const rectRef = React.useRef<SVGRectElement>();

    React.useEffect(() => {
        if (!rectRef.current) {
            return;
        }

        const pathVehicleLength = rectRef.current.width.baseVal.value;
        const svgVehiclePathTo = Math.floor((pathVehicleLength * props.progressCollected.getPercentProgressEnsign()) / 100);

        rectRef.current.style.width = `${svgVehiclePathTo}px`;
    }, []);

    if (!isAuthorizedUser()) {
        return null;
    }

    return (
        <svg width="2500" height="1406" viewBox="0 0 2500 1406" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.svgProgress)} ref={ref}>
            <g filter="url(#ensignsProgressG)">
                <path d="M1298.34 404L1653 417V458L1302.84 490L1284 449L1298.34 404Z" fill="black" fillOpacity="0.3" />
            </g>
            <path
                opacity="0.5"
                d="M1305.96 453.502L1305.5 453.538V454V464V464.541L1306.04 464.498L1639.04 438.498L1639.5 438.462V438V428V427.459L1638.96 427.502L1305.96 453.502Z"
                fill="black"
                stroke="white"
            />
            <mask id="ensignsProgressMask" mask-type="alpha" maskUnits="userSpaceOnUse" x="1306" y="428" width="333" height="36">
                <path d="M1306 454L1639 428V438L1306 464V454Z" fill="#FFCC00" />
            </mask>
            <g mask="url(#ensignsProgressMask)">
                <rect x="1305" y="427" width="334" height="37" fill="#FFCC00" ref={rectRef} />
            </g>
            <defs>
                <filter id="ensignsProgressG" x="1244" y="364" width="449" height="166" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="20" result="effect1_foregroundBlur" />
                </filter>
            </defs>
            <text x={'1308'} y={'529'} className={classNames(styles.textProgressBar, styles.textProgressBarEnsigns)}>
                {t('Флаги')} {props.progressCollected.getAvailableEnsignsCount()}/{props.progressCollected.getTotalEnsignsCount()}
            </text>
        </svg>
    );
});

export default EnsignsProgressBar;
