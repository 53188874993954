import { Doll } from '~/vortex';
import { _Item, DefaultDogTagComponentsData } from '~/interfaces';
import ResponseItemMap from './index';
import { DOLLS_CATEGORY } from '~/Actions/ActionCategory';
import { DEFAULT_DOG_TAG_COMPONENTS } from '~/helpers/consts';

export const prepareDolls = (responseDolls: Doll[]): Doll[] => {
    const rareDolls: Doll[] = [];
    const dolls = responseDolls
        .map((item: Doll & _Item) => {
            if (ResponseItemMap.isLegendaryItem(DOLLS_CATEGORY, item)) {
                item.isRareItem = true;
                rareDolls.push(item);
            } else if (ResponseItemMap.isUniqueItem(DOLLS_CATEGORY, item)) {
                item.isUniqueItem = true;
            }

            return item;
        })
        .filter((item) => {
            if (ResponseItemMap.getExcludedDollsIds().includes(item.id)) {
                return false;
            }

            return !item.isRareItem;
        });

    return [...rareDolls, ...dolls];
};

export function getDefaultDogTagComponents(responseDolls: Doll[]): DefaultDogTagComponentsData {
    return responseDolls.reduce<DefaultDogTagComponentsData>((acc, doll) => {
        switch (doll.id) {
            case DEFAULT_DOG_TAG_COMPONENTS.TEXTURE_ID:
                acc.textureData = doll.textureData;
                break;
            case DEFAULT_DOG_TAG_COMPONENTS.BACKGROUND_COLOR_ID:
                acc.backgroundColor = doll.color;
                break;
            case DEFAULT_DOG_TAG_COMPONENTS.BORDER_COLOR_ID:
                acc.borderColor = doll.color;
                break;
        }
        return acc;
    }, {});
}
