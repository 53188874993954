import * as React from 'react';
import styles from '~/Components/Profile/Profile.scss';
import { ENSIGNS_CATEGORY } from '~/Actions/ActionCategory';
import { IItemSVG } from '~/Components/Profile/SvgRoom';

const EnsignSVG = React.forwardRef((props: IItemSVG, ref: any) => {
    return (
        <g
            className={styles.svgG}
            onClick={() => {
                props.onClick?.(ENSIGNS_CATEGORY);
            }}
            onMouseMove={() => {
                props.onMouseMove?.(true, ENSIGNS_CATEGORY);
            }}
            onMouseLeave={() => {
                props.onMouseLeave?.(false, ENSIGNS_CATEGORY);
            }}
            onMouseEnter={() => {
                props.onMouseEnter?.(false, ENSIGNS_CATEGORY);
            }}
            ref={(_ref) => {
                ref.current = _ref;
            }}
        >
            <mask id="mask01" mask-type="alpha" maskUnits="userSpaceOnUse" x="1299" y="276" width="395" height="373">
                <path
                    d="M1471.1 301.7C1376.6 315.3 1299.2 326.8 1299.1 327.4C1299 327.9 1299 384.1 1299.1 452.3L1299.4 576.2L1398.6 575.6C1453.2 575.2 1526.9 574.7 1562.5 574.3L1627.1 573.7L1628.2 566.2C1628.8 562.1 1629.6 558.3 1629.9 557.8C1630.2 557.3 1635.9 556.9 1642.6 556.9C1657.9 556.9 1657.3 556.1 1656.5 576.4L1656 590.9L1659.2 597.8C1661.8 603.5 1662.6 607 1663.8 618C1665.5 634.2 1667.4 639.9 1672.5 645C1676.2 648.7 1676.6 648.9 1682.9 648.9H1689.4V630.1C1689.4 619.8 1689.8 584.1 1690.3 550.8C1692.1 432.1 1693.7 290.8 1693.3 290.4C1692.3 289.5 1647.2 276.8 1645.1 276.9C1643.8 277.1 1565.6 288.2 1471.1 301.7Z"
                    fill="white"
                    fillOpacity="0.8"
                />
            </mask>
            <g mask="url(#mask01)">
                <path
                    d="M1292 577.5V308.5L1315 299L1311 282.5L1316.5 269L1344 215.5C1391.5 205.833 1492.4 185 1516 179C1539.6 173 1618.5 156.5 1655 149L1681 235V261.5L1695 290L1696 641L1692.5 655.5C1684.67 654.667 1668.5 652.7 1666.5 651.5C1664.5 650.3 1658.67 617 1656 600.5L1654.5 557H1630L1627.5 573.5L1299.5 576L1292 577.5Z"
                    fill="url(#paint0_linear1)"
                />
            </g>
            <defs>
                <linearGradient id="paint0_linear1" x1="1494" y1="149" x2="1494" y2="655.5" gradientUnits="userSpaceOnUse">
                    <stop offset="0.176132" stopColor="rgba(255, 255, 255, .7)" />
                    <stop offset="0.422629" stopColor="rgba(255, 255, 255, .7)" />
                    <stop offset="0.862351" stopColor="rgba(255, 255, 255, 0)" />
                </linearGradient>
            </defs>
        </g>
    );
});

export default EnsignSVG;
