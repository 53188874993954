import { IInventory, IInventoryState, IResponse } from '~/interfaces';
import { getArrayFromObject } from '~/helpers/array';

export const prepareInventory = (inventory: IInventory, response: IResponse): IInventoryState => {
    const resultInventory: IInventoryState = {};

    const uniqueCrews = getArrayFromObject(response.crews);
    const crew = inventory.crew.reduce((array, item) => {
        if (uniqueCrews.includes(item.id)) {
            array.push(item);
        }

        return array;
    }, []);

    resultInventory['crew'] = Array.from(crew.reduce((Set, item) => Set.add(item.id), new Set()));

    resultInventory['hold'] = inventory.hold.reduce((state, item) => {
        state.push(item.id);

        return state;
    }, []);

    const arrayEnsigns = getArrayFromObject(response.ensigns);
    resultInventory['storage'] = inventory.storage.reduce((array, item) => {
        if (arrayEnsigns.includes(item.id)) {
            array.push(item.id);
        }
        return array;
    }, []);

    const arrayDolls = getArrayFromObject(response.dogTagComponents);
    resultInventory['dogTag'] = inventory.dogTag.reduce((array, item) => {
        if (arrayDolls.includes(item)) {
            array.push(item);
        }

        return array;
    }, []);

    return Object.assign(inventory, resultInventory);
};
