import { promoWidgetTypes } from '~/helpers/consts';
import { t } from '~/helpers/localization';

export const promoTexts = {
    [promoWidgetTypes.welcome]: [
        {
            title: t('Судовой журнал'),
            description: [
                t('Судовой журнал позволит вам отслеживать свой прогресс в сборе нашивок, командиров, камуфляжей, флагов и, конечно, кораблей.'),
                t('Не упустите возможность ознакомиться со множеством различных предметов, представленных в игре!'),
            ].join(' '),
        },
        {
            title: t('Категории и предметы'),
            description: [
                t('Помимо Главной страницы Судовой журнал содержит 5 разделов.'),
                t('Во всех них доступен поиск по названиям, фильтры и настройка отображения, в том числе - возможность скрыть авторские предметы.'),
            ].join(' '),
        },
        {
            title: t('Получение предметов'),
            description: t('Получить новые предметы для Судового журнала можно в Адмиралтействе, Премиум магазине, Дереве развития, в награду за коллекции и за игровые активности и успехи.'),
        },
    ],
};
