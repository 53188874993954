import * as React from 'react';
import styles from './Profile.scss';
import { ICategories } from '~/interfaces';
import { DWH_EVENTS, Places, navigate } from '~/helpers/consts';
import { pushToHistory } from '~/helpers/history';
import ProgressCollected from '~/Components/Profile/ProgressCollected';
import CamoSVG from '~/Components/Profile/svg/Camo';
import EnsignSVG from '~/Components/Profile/svg/Ensigns';
import DollsSVG from '~/Components/Profile/svg/Dolls';
import VehiclesSVG from '~/Components/Profile/svg/Vehicles';
import CrewsSVG from '~/Components/Profile/svg/Crews';
import VehicleProgressBar from '~/Components/Profile/progress/Vehicles';
import CrewsProgressBar from '~/Components/Profile/progress/Crews';
import EnsignsProgressBar from '~/Components/Profile/progress/Ensigns';
import DollsProgressBar from '~/Components/Profile/progress/Dolls';
import CamoProgressBar from '~/Components/Profile/progress/Camo';
import { CREWS_CATEGORY, DOLLS_CATEGORY, ENSIGNS_CATEGORY, PERMOFLAGES_CATEGORY, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';
import { playButtonClickSound, playCardOverSound } from '~/helpers/audioApi';
import dwhExport from '~/helpers/dwhExport';

interface ISvgRoom {
    progressCollected: ProgressCollected;
}

export interface IItemSVG {
    onClick: (category: ICategories) => void;
    onMouseMove: (isVisible: boolean, category: ICategories) => void;
    onMouseLeave: (isVisible: boolean, category: ICategories) => void;
    onMouseEnter?: (isVisible: boolean, category: ICategories) => void;
}

export interface IProgressSVG {
    progressCollected: ProgressCollected;
}

const SvgRoom = (props: ISvgRoom) => {
    const ensignRef = React.useRef(null);
    const dollsRef = React.useRef(null);
    const vehiclesRef = React.useRef(null);
    const crewsRef = React.useRef(null);
    const camoRef = React.useRef(null);

    const vehiclesProgressBarRef = React.useRef(null);
    const crewsProgressBarRef = React.useRef(null);
    const ensignsProgressBarRef = React.useRef(null);
    const camoProgressBarRef = React.useRef(null);
    const dollsProgressBarRef = React.useRef(null);

    const maps = {
        [VEHICLE_CATEGORY]: vehiclesProgressBarRef,
        [CREWS_CATEGORY]: crewsProgressBarRef,
        [ENSIGNS_CATEGORY]: ensignsProgressBarRef,
        [PERMOFLAGES_CATEGORY]: camoProgressBarRef,
        [DOLLS_CATEGORY]: dollsProgressBarRef,
    };

    const clickByCategory = (category: ICategories) => {
        playButtonClickSound();
        dwhExport.send(DWH_EVENTS.PAGE_OPEN, { name: category, from: Places.MAIN_PAGE });
        pushToHistory(navigate[category]);
    };
    const changeVisibleProgressBar = (isVisible: boolean, category: ICategories) => {
        if (maps[category]?.current) {
            maps[category].current.style.opacity = Number(isVisible);
        }
    };

    return (
        <div className={styles.svgContainer}>
            <svg className={styles.svgMasks} xmlns="http://www.w3.org/2000/svg" width="2500" height="1406" viewBox="0 0 2500 1406" version="1.1" preserveAspectRatio="xMidYMid meet">
                <EnsignSVG onClick={clickByCategory} onMouseMove={changeVisibleProgressBar} onMouseLeave={changeVisibleProgressBar} onMouseEnter={playCardOverSound} ref={ensignRef} />
                <DollsSVG onClick={clickByCategory} onMouseMove={changeVisibleProgressBar} onMouseLeave={changeVisibleProgressBar} onMouseEnter={playCardOverSound} ref={dollsRef} />
                <VehiclesSVG onClick={clickByCategory} onMouseMove={changeVisibleProgressBar} onMouseLeave={changeVisibleProgressBar} onMouseEnter={playCardOverSound} ref={vehiclesRef} />
                <CrewsSVG onClick={clickByCategory} onMouseMove={changeVisibleProgressBar} onMouseLeave={changeVisibleProgressBar} onMouseEnter={playCardOverSound} ref={crewsRef} />
                <CamoSVG onClick={clickByCategory} onMouseMove={changeVisibleProgressBar} onMouseLeave={changeVisibleProgressBar} onMouseEnter={playCardOverSound} ref={camoRef} />
            </svg>
            <VehicleProgressBar progressCollected={props.progressCollected} ref={vehiclesProgressBarRef} />
            <CrewsProgressBar progressCollected={props.progressCollected} ref={crewsProgressBarRef} />
            <EnsignsProgressBar progressCollected={props.progressCollected} ref={ensignsProgressBarRef} />
            <DollsProgressBar progressCollected={props.progressCollected} ref={dollsProgressBarRef} />
            <CamoProgressBar progressCollected={props.progressCollected} ref={camoProgressBarRef} />
        </div>
    );
};

export default SvgRoom;
