import * as React from 'react';
import styles from '~/Components/Profile/Profile.scss';
import classNames from 'classnames';
import { PERMOFLAGES_CATEGORY } from '~/Actions/ActionCategory';
import { IItemSVG } from '~/Components/Profile/SvgRoom';

const CamoSVG = React.forwardRef((props: IItemSVG, ref: any) => {
    return (
        <React.Fragment>
            <defs>
                <filter id="permoflagesFilter" filterUnits="userSpaceOnUse" x="1202.4" y="524.3" width="453.3" height="315.8">
                    <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="1202.4" y="524.3" width="453.3" height="315.8" id="permoflagesFilterMask1">
                <g className={styles.permoMaskSt0}>
                    <path
                        className={styles.permoMaskSt1}
                        d="M1649.8,782.5l-0.3-8c-0.2-4.4,0.6-40.2,1.8-79.5c3.7-125.2,3.7-126,5-127.5c1-1.1,0.9-2.4-0.1-6l-1.3-4.5
                        h-12.3c-8.9,0-12.4,0.3-12.8,1.3c-0.3,0.7-0.9,4.3-1.4,8.1c-0.5,3.8-1.2,7.1-1.5,7.4c-0.7,0.7-28.8,1.2-73.5,1.5
                        c0-0.1-0.1-0.2-0.2-0.3c-1.5-2.3-3.4-4.2-5.8-5.6l-0.5-0.9c-0.1-0.3-0.4-0.5-0.6-0.7c0.3-1.6,0.2-3.2-0.2-4.8
                        c-0.9-3-4.6-4.7-7.3-3c-1.5,1-2.9,2.2-3.7,3.8c-4.5-3.4-9.5-5.9-14.3-8.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.5-1-0.8-1.5-0.8
                        c-0.6-0.4-1.3-0.8-1.9-1.3c-7.1-5-14-10.4-20.8-15.8c0.9-1.7,1.1-3.7,0.6-5.5c-0.9-3.2-4.1-5.6-7.5-5.4c-2.5,0.2-4.8,1.5-6.1,3.5
                        c-15.2,0.6-30.4,1.5-45.5,2.7c-1-1.4-2.5-2.6-4.3-3.1c-3.3-1.1-7.2,0.2-9,3.1c-1.2,2-1,4.3-0.2,6.4c-2.1,1.2-4.3,2.3-6.6,3.2
                        c0,0-0.1,0-0.1,0.1c-0.2-0.3-0.4-0.6-0.5-0.9c-0.6-2.6-3.7-4.2-6.2-3.5c0,0-0.1,0-0.1,0c-6-6.3-18.8-6.7-27.1-3
                        c-5.9,2.6-9.9,9.4-11,15.6c-0.4,2.2-0.1,4.1,0.8,5.7c-3.9,6.2-5.5,13.7-0.5,20.1c0,0.1,0.1,0.1,0.1,0.2l-79.7,0l-1.3-2.5
                        c-1.6-3.1-2.7-3.1-5.7-0.3c-1.9,1.7-3.9,2.3-10.4,2.6l-8.1,0.5l0.7-8.1l0.7-8.1h-12.1h-12.1l-1.6,3.2c-1,2-1.4,4.4-1.1,6.6
                        c0.3,2-3.7,33.1-9.9,75.6c-5.7,39.7-12,83.4-14,97.1c-4.7,32.6-3.8,29.5-8.4,29.5c-3.5,0-3.9,0.3-4.5,3.1
                        c-1.5,7.6,0.5,13.9,4.5,13.9c1.5,0,1.8,0.5,1.4,2.3c-0.3,1.2-1.4,8.8-2.5,16.7c-1.1,7.9-2.5,14.7-3,15.1c-0.6,0.3-0.9,4.1-0.7,8.5
                        c0.2,7.3,0.4,8,2.4,8.2c3.3,0.5,9.6-1.5,13.7-4.3c4.2-2.9,11.5-3.3,15.6-1c2.9,1.7,11.3,2,14,0.5c1.6-0.9,2-0.6,2.5,1.5
                        c0.3,1.4,1.3,2.5,2.1,2.5c0.8,0,1.6,0.6,1.8,1.3c0.7,2,3.7,33.6,3.9,40.3l0.2,6l6.3,0.3c6,0.3,6.6,0.1,12-3.8l5.7-4l0.3,47.3
                        c0.2,30.3-0.1,51.4-0.8,58.8c-0.6,6.3-1.3,11.7-1.5,11.9c-0.2,0.2-2,0.1-3.9-0.3l-3.5-0.7V990c0-5.7-0.1-5.9-3.1-6.5l-3.1-0.7
                        l0.7-5.6l0.7-5.6l-3.2,0.6c-1.8,0.4-9.5,0.9-17.1,1.3c-12.7,0.6-14.6,0.5-22-1.7c-8.1-2.3-8.3-2.3-16.9-0.7
                        c-4.8,0.9-12.1,1.7-16.2,1.7c-4.6,0-13.2,1.2-22.4,3.2c-8.2,1.7-16.9,3.3-19.3,3.4c-2.4,0.1-4.6,0.6-5,1.2
                        c-0.4,0.5-5.6,1.5-11.6,2.2c-16,1.8-14.5,0.5-14.5,12.7v10.4l-5.3,0c-2.9,0-7.3,0.6-9.8,1.3c-5.8,1.6-15.1,6.9-17,9.7
                        c-0.8,1.2-2.1,2.8-2.8,3.6c-0.7,0.8-1,1.4-0.6,1.4c0.4,0,0,0.8-0.8,1.8c-3.5,4-4.8,13.8-3.3,24.2c0.7,4.5,6.2,16.2,9.3,19.6
                        c0.9,1,3,3.7,4.6,5.9c13.2,18.1,26.8,26.9,51.6,33.6c4.4,1.2,6,1.5,11,2.3c1.4,0.2,3.4,0.6,4.5,1c10.2,3.1,40.3,2.7,54.8-0.7
                        c26.6-6.2,47.5-17.5,65.4-35.3c5.1-5.1,9.3-9.7,9.3-10.2c0-0.5,0.6-1.6,1.4-2.3c2.1-2.1,7.5-13.3,8.4-17.2
                        c0.5-2.5,4.1-7.1,12.7-16.4c11.1-12,12.3-13,15.7-13c3.4-0.1,4.8-1.3,22.3-19.6c16.3-17.1,18.6-19.9,18.6-22.8
                        c0-1.8,3.8-20.6,8.4-41.8l8.4-38.5h15.9c8.7,0,32,0.4,51.6,0.8l35.8,0.8v-27.4c0-15.1,0.3-36.3,0.7-47.1l0.7-19.6l36.6,0.5
                        c20.1,0.3,40.2,0.8,44.6,1l8,0.5v22.2v22.2l4,0.1c2.2,0,4.8,0.4,5.7,0.8c0.9,0.4,4.1,0.5,7,0.2c17.9-1.9,17.7-1.9,17.8-6.4
                        c0-1.3,0.6-2.3,1.5-2.3c0.8,0,1.5,0.4,1.5,0.8c0,1.6,7.3,9.4,11.1,11.7c2.1,1.3,6,2.7,8.7,3l5,0.7l0.7-5.1
                        c0.4-2.8,1.1-5.1,1.6-5.1c1.3,0,1.1-5.4-0.2-8.4c-0.8-1.7-0.9-8-0.4-21.3c0.5-12.1,1.1-18,1.6-16.6c1,2.8,5.8,3.2,5.8,0.5
                        c0-1,0.3-5,0.7-8.9l0.6-7.2l-3.4-0.3L1649.8,782.5z M1420.2,575.9l-4.5,0c3-2.4,5.4-5.5,7.1-9.4c1.7-4.1,2.5-8.6,1.8-13
                        c-0.5-3.1-1.8-4.9-3.6-6.5c2.2-1.2,4.4-2.4,6.6-3.7c0.8,0.5,1.7,0.8,2.7,0.9c1.4,0.6,3,0.5,4.5-0.2c2.6-1.2,4.4-4.3,4.5-7.1
                        c14.4-1.1,28.7-1.9,43.1-2.5c0.5,2.8,2.6,5.3,5.5,6.1c0.9,0.2,1.8,0.2,2.7-0.1c0.7,0,1.3-0.1,2-0.3c6.3,5,12.6,10,19.2,14.7
                        c1.7,1.3,3.6,2.4,5.4,3.5c2.5,2.8,5.8,4.6,8.9,6.6c3,2,5.9,4.1,8.7,6.3c-0.3,0.2-0.6,0.4-0.9,0.6c-1.2,0.9-2.1,2.1-2.4,3.5
                        C1499.6,575.6,1461.5,575.8,1420.2,575.9z"
                    />
                </g>
            </mask>
            <linearGradient
                id="permoflagesFilterGradient1"
                gradientUnits="userSpaceOnUse"
                x1="1421.6722"
                y1="315.8489"
                x2="1429.2021"
                y2="835.3898"
                gradientTransform="matrix(0.9977 -1.446082e-02 -1.446082e-02 0.9071 15.1517 97.3269)"
            >
                <stop offset="0.176132" stopColor="rgba(255, 255, 255, .7)" />
                <stop offset="0.422629" stopColor="rgba(255, 255, 255, .7)" />
                <stop offset="0.862351" stopColor="rgba(255, 255, 255, 0)" />
            </linearGradient>
            <path
                className={styles.permoMaskSt2}
                d="M1646.2,556.5c-5.7,0.3-11.3,0.4-17-0.1c-0.8,4.1-1.8,8.2-2.9,12.2c-1.2,4.7-6.3,5.2-9.4,3
                c-0.3,0.1-0.6,0.1-0.9,0.2c-8.2,1-16.5,0.1-24.7-0.3c-2.8-0.1-5.5-0.4-8.3-0.6c-1.9,0-3.7-0.1-5.6-0.2c-3,0-6,0.3-9,1.1
                c-1.7,0.5-3.1,0.2-4.3-0.4c-0.6,0.2-1.3,0.4-2,0.4c-2.1,0-4.2,0-6.3-0.3c-3.2-0.3-5.5-2.3-5.9-5.4c-1.2-1.2-1.7-3-1.6-4.7
                c-0.5-0.4-0.8-0.8-1.2-1.2c-0.9-0.4-1.6-1.1-2.1-1.8c-2.3-0.2-4.6-0.3-6.9-0.6c-3,1.3-6.4,0.6-9.6-0.9c-1.1-0.1-2.2-0.6-3.2-1.6
                c-0.4-0.4-0.9-0.8-1.3-1.1c-0.2-0.1-0.4-0.3-0.6-0.4c-1.7-1.2-3.3-2.6-4.9-3.9c-1.2-0.9-2.4-1.8-3.5-2.6c-2.9-2.1-5.8-5.4-9.3-6.4
                c-1.7-0.5-2.9-1.5-3.6-2.7c-2.1-1-3-3.5-2.8-5.8l-0.1-0.1c-1.1-1.1-1.7-2.7-1.8-4.2c0-0.4,0-0.8,0.1-1.3c-0.5-0.1-1-0.3-1.6-0.6
                c-0.6-0.3-1.2-0.9-1.7-1.5c-3.7-0.4-9-0.7-11.9,1.6c-1.8,1.5-4.1,2.1-6.2,1.4c-3.9,0.9-8.1,0.9-12.1,1.2c-8.3,0.7-16.9,2.2-25-0.3
                c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0-0.6,0c-1.8,0-3.2-0.7-4.2-1.7c-1.1,0-2.2,0.1-3.4,0.1c-0.9,0.8-2.2,1.3-3.8,1.3
                c-0.4,0-0.8,0-1.2-0.1c-0.5,1-1.2,1.8-2.2,2.4c0.1,4.8-6,9-10.1,4.6c-6.5-6.9-18.5-3.8-27.5-3.3c-0.9,0.9-2.1,1.5-3.6,1.6
                c-0.9,1.1-2.1,2-3.2,3c-0.6,0.5-1.2,1.1-1.7,1.6c0,0,0,0.1,0,0.1c-0.2,0.4-0.4,0.7-0.6,1.1c-0.6,1.4-1.8,2.2-3.1,2.6
                c-1.1,7.4-1.3,15-5.7,21.4c-0.3,0.4-0.6,0.8-1,1.1c1.7,3.5-0.1,8.8-5.2,8.8c-4.6,0-9.3,0.2-13.9,0.1c-0.4,0.1-0.8,0.2-1.3,0.3
                c-16.7,2.2-33.6,2.9-50.3,0.1c-2.9-0.5-4.2-2.4-4.3-4.5c-0.8-0.1-1.6-0.5-2.3-0.9c-0.6,0.6-1.4,1.1-2.4,1.4
                c-0.5,0.8-1.1,1.5-2.1,2.1c-6.2,3.9-9.3-14-16.2-14.7c-7.1-0.7-17.5-2.8-24.5-2.5c-1.7,1.3-4.6,20.8-6.6,21.4
                c-0.1,0.7-0.2,1.3-0.5,2c0.1,0.8,0.2,1.5,0.3,2.3c0.2,3.7-1.1,7.2-3.2,10c-1.4,17-2.8,34-5.3,50.9c-1.3,8.7-2.9,17.4-5.5,25.8
                c-1.4,11.7-2.8,23.7-4.2,35.9c-0.7,6.2-1.5,12.3-2.3,18.5c-0.5,3.5-0.9,6.9-1.4,10.4c-0.1,1-0.3,1.9-0.4,2.9
                c-0.1,0.5-0.3,2.3-0.3,2.7c-1.3,11-10.6,32-11.6,42.9c-1.1,12.2,5.9,14.3,3.3,26.3c-1.2,5.6-2.4,11.1-3.4,16.8
                c-0.1,0.4-0.1,0.8-0.2,1.1c0,0.1,0,0.4-0.1,0.8c-0.1,1.2-0.2,2.5-0.3,3.7c-0.1,2.3-0.2,9.2-0.1,11.5c67,0.9,139.8-8.7,215.1-20.4
                c85-13.2,165-40.7,234.5-36.7c-0.2-15.1-1.3-52.2-0.6-67.3c0.8-17.1,1.4-34.3,2-51.4c1.2-34.8,2.1-69.5,2.2-104.3
                c0-0.9,0.1-1.7,0.2-2.5C1652.5,557.1,1649.3,556.8,1646.2,556.5z"
            />
            <defs>
                <filter id="permoflagesFilter1" filterUnits="userSpaceOnUse" x="1202.4" y="524.3" width="453.3" height="315.8">
                    <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="1202.4" y="524.3" width="453.3" height="315.8" id="permoflagesFilterMask2">
                <g className={styles.permoMaskSt3}>
                    <path
                        className={classNames(styles.permoMaskSt1, styles.pathTransition)}
                        d="M1649.8,782.5l-0.3-8c-0.2-4.4,0.6-40.2,1.8-79.5c3.7-125.2,3.7-126,5-127.5c1-1.1,0.9-2.4-0.1-6l-1.3-4.5
                        h-12.3c-8.9,0-12.4,0.3-12.8,1.3c-0.3,0.7-0.9,4.3-1.4,8.1c-0.5,3.8-1.2,7.1-1.5,7.4c-0.7,0.7-28.8,1.2-73.5,1.5
                        c0-0.1-0.1-0.2-0.2-0.3c-1.5-2.3-3.4-4.2-5.8-5.6l-0.5-0.9c-0.1-0.3-0.4-0.5-0.6-0.7c0.3-1.6,0.2-3.2-0.2-4.8
                        c-0.9-3-4.6-4.7-7.3-3c-1.5,1-2.9,2.2-3.7,3.8c-4.5-3.4-9.5-5.9-14.3-8.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.5-1-0.8-1.5-0.8
                        c-0.6-0.4-1.3-0.8-1.9-1.3c-7.1-5-14-10.4-20.8-15.8c0.9-1.7,1.1-3.7,0.6-5.5c-0.9-3.2-4.1-5.6-7.5-5.4c-2.5,0.2-4.8,1.5-6.1,3.5
                        c-15.2,0.6-30.4,1.5-45.5,2.7c-1-1.4-2.5-2.6-4.3-3.1c-3.3-1.1-7.2,0.2-9,3.1c-1.2,2-1,4.3-0.2,6.4c-2.1,1.2-4.3,2.3-6.6,3.2
                        c0,0-0.1,0-0.1,0.1c-0.2-0.3-0.4-0.6-0.5-0.9c-0.6-2.6-3.7-4.2-6.2-3.5c0,0-0.1,0-0.1,0c-6-6.3-18.8-6.7-27.1-3
                        c-5.9,2.6-9.9,9.4-11,15.6c-0.4,2.2-0.1,4.1,0.8,5.7c-3.9,6.2-5.5,13.7-0.5,20.1c0,0.1,0.1,0.1,0.1,0.2l-79.7,0l-1.3-2.5
                        c-1.6-3.1-2.7-3.1-5.7-0.3c-1.9,1.7-3.9,2.3-10.4,2.6l-8.1,0.5l0.7-8.1l0.7-8.1h-12.1h-12.1l-1.6,3.2c-1,2-1.4,4.4-1.1,6.6
                        c0.3,2-3.7,33.1-9.9,75.6c-5.7,39.7-12,83.4-14,97.1c-4.7,32.6-3.8,29.5-8.4,29.5c-3.5,0-3.9,0.3-4.5,3.1
                        c-1.5,7.6,0.5,13.9,4.5,13.9c1.5,0,1.8,0.5,1.4,2.3c-0.3,1.2-1.4,8.8-2.5,16.7c-1.1,7.9-2.5,14.7-3,15.1c-0.6,0.3-0.9,4.1-0.7,8.5
                        c0.2,7.3,0.4,8,2.4,8.2c3.3,0.5,9.6-1.5,13.7-4.3c4.2-2.9,11.5-3.3,15.6-1c2.9,1.7,11.3,2,14,0.5c1.6-0.9,2-0.6,2.5,1.5
                        c0.3,1.4,1.3,2.5,2.1,2.5c0.8,0,1.6,0.6,1.8,1.3c0.7,2,3.7,33.6,3.9,40.3l0.2,6l6.3,0.3c6,0.3,6.6,0.1,12-3.8l5.7-4l0.3,47.3
                        c0.2,30.3-0.1,51.4-0.8,58.8c-0.6,6.3-1.3,11.7-1.5,11.9c-0.2,0.2-2,0.1-3.9-0.3l-3.5-0.7V990c0-5.7-0.1-5.9-3.1-6.5l-3.1-0.7
                        l0.7-5.6l0.7-5.6l-3.2,0.6c-1.8,0.4-9.5,0.9-17.1,1.3c-12.7,0.6-14.6,0.5-22-1.7c-8.1-2.3-8.3-2.3-16.9-0.7
                        c-4.8,0.9-12.1,1.7-16.2,1.7c-4.6,0-13.2,1.2-22.4,3.2c-8.2,1.7-16.9,3.3-19.3,3.4c-2.4,0.1-4.6,0.6-5,1.2
                        c-0.4,0.5-5.6,1.5-11.6,2.2c-16,1.8-14.5,0.5-14.5,12.7v10.4l-5.3,0c-2.9,0-7.3,0.6-9.8,1.3c-5.8,1.6-15.1,6.9-17,9.7
                        c-0.8,1.2-2.1,2.8-2.8,3.6c-0.7,0.8-1,1.4-0.6,1.4c0.4,0,0,0.8-0.8,1.8c-3.5,4-4.8,13.8-3.3,24.2c0.7,4.5,6.2,16.2,9.3,19.6
                        c0.9,1,3,3.7,4.6,5.9c13.2,18.1,26.8,26.9,51.6,33.6c4.4,1.2,6,1.5,11,2.3c1.4,0.2,3.4,0.6,4.5,1c10.2,3.1,40.3,2.7,54.8-0.7
                        c26.6-6.2,47.5-17.5,65.4-35.3c5.1-5.1,9.3-9.7,9.3-10.2c0-0.5,0.6-1.6,1.4-2.3c2.1-2.1,7.5-13.3,8.4-17.2
                        c0.5-2.5,4.1-7.1,12.7-16.4c11.1-12,12.3-13,15.7-13c3.4-0.1,4.8-1.3,22.3-19.6c16.3-17.1,18.6-19.9,18.6-22.8
                        c0-1.8,3.8-20.6,8.4-41.8l8.4-38.5h15.9c8.7,0,32,0.4,51.6,0.8l35.8,0.8v-27.4c0-15.1,0.3-36.3,0.7-47.1l0.7-19.6l36.6,0.5
                        c20.1,0.3,40.2,0.8,44.6,1l8,0.5v22.2v22.2l4,0.1c2.2,0,4.8,0.4,5.7,0.8c0.9,0.4,4.1,0.5,7,0.2c17.9-1.9,17.7-1.9,17.8-6.4
                        c0-1.3,0.6-2.3,1.5-2.3c0.8,0,1.5,0.4,1.5,0.8c0,1.6,7.3,9.4,11.1,11.7c2.1,1.3,6,2.7,8.7,3l5,0.7l0.7-5.1
                        c0.4-2.8,1.1-5.1,1.6-5.1c1.3,0,1.1-5.4-0.2-8.4c-0.8-1.7-0.9-8-0.4-21.3c0.5-12.1,1.1-18,1.6-16.6c1,2.8,5.8,3.2,5.8,0.5
                        c0-1,0.3-5,0.7-8.9l0.6-7.2l-3.4-0.3L1649.8,782.5z M1420.2,575.9l-4.5,0c3-2.4,5.4-5.5,7.1-9.4c1.7-4.1,2.5-8.6,1.8-13
                        c-0.5-3.1-1.8-4.9-3.6-6.5c2.2-1.2,4.4-2.4,6.6-3.7c0.8,0.5,1.7,0.8,2.7,0.9c1.4,0.6,3,0.5,4.5-0.2c2.6-1.2,4.4-4.3,4.5-7.1
                        c14.4-1.1,28.7-1.9,43.1-2.5c0.5,2.8,2.6,5.3,5.5,6.1c0.9,0.2,1.8,0.2,2.7-0.1c0.7,0,1.3-0.1,2-0.3c6.3,5,12.6,10,19.2,14.7
                        c1.7,1.3,3.6,2.4,5.4,3.5c2.5,2.8,5.8,4.6,8.9,6.6c3,2,5.9,4.1,8.7,6.3c-0.3,0.2-0.6,0.4-0.9,0.6c-1.2,0.9-2.1,2.1-2.4,3.5
                        C1499.6,575.6,1461.5,575.8,1420.2,575.9z"
                    />
                </g>
            </mask>
            <linearGradient
                id="permoflagesFilterGradient2"
                gradientUnits="userSpaceOnUse"
                x1="1421.6722"
                y1="315.8489"
                x2="1429.2021"
                y2="835.3898"
                gradientTransform="matrix(0.9977 -1.446082e-02 -1.446082e-02 0.9071 15.1517 97.3269)"
            >
                <stop offset="0.176132" stopColor="rgba(255, 255, 255, .7)" />
                <stop offset="0.422629" stopColor="rgba(255, 255, 255, .7)" />
                <stop offset="0.862351" stopColor="rgba(255, 255, 255, 0)" />
            </linearGradient>
            <path
                className={styles.permoMaskSt4}
                d="M1646.2,556.5c-5.7,0.3-11.3,0.4-17-0.1c-0.8,4.1-1.8,8.2-2.9,12.2c-1.2,4.7-6.3,5.2-9.4,3
                c-0.3,0.1-0.6,0.1-0.9,0.2c-8.2,1-16.5,0.1-24.7-0.3c-2.8-0.1-5.5-0.4-8.3-0.6c-1.9,0-3.7-0.1-5.6-0.2c-3,0-6,0.3-9,1.1
                c-1.7,0.5-3.1,0.2-4.3-0.4c-0.6,0.2-1.3,0.4-2,0.4c-2.1,0-4.2,0-6.3-0.3c-3.2-0.3-5.5-2.3-5.9-5.4c-1.2-1.2-1.7-3-1.6-4.7
                c-0.5-0.4-0.8-0.8-1.2-1.2c-0.9-0.4-1.6-1.1-2.1-1.8c-2.3-0.2-4.6-0.3-6.9-0.6c-3,1.3-6.4,0.6-9.6-0.9c-1.1-0.1-2.2-0.6-3.2-1.6
                c-0.4-0.4-0.9-0.8-1.3-1.1c-0.2-0.1-0.4-0.3-0.6-0.4c-1.7-1.2-3.3-2.6-4.9-3.9c-1.2-0.9-2.4-1.8-3.5-2.6c-2.9-2.1-5.8-5.4-9.3-6.4
                c-1.7-0.5-2.9-1.5-3.6-2.7c-2.1-1-3-3.5-2.8-5.8l-0.1-0.1c-1.1-1.1-1.7-2.7-1.8-4.2c0-0.4,0-0.8,0.1-1.3c-0.5-0.1-1-0.3-1.6-0.6
                c-0.6-0.3-1.2-0.9-1.7-1.5c-3.7-0.4-9-0.7-11.9,1.6c-1.8,1.5-4.1,2.1-6.2,1.4c-3.9,0.9-8.1,0.9-12.1,1.2c-8.3,0.7-16.9,2.2-25-0.3
                c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0-0.6,0c-1.8,0-3.2-0.7-4.2-1.7c-1.1,0-2.2,0.1-3.4,0.1c-0.9,0.8-2.2,1.3-3.8,1.3
                c-0.4,0-0.8,0-1.2-0.1c-0.5,1-1.2,1.8-2.2,2.4c0.1,4.8-6,9-10.1,4.6c-6.5-6.9-18.5-3.8-27.5-3.3c-0.9,0.9-2.1,1.5-3.6,1.6
                c-0.9,1.1-2.1,2-3.2,3c-0.6,0.5-1.2,1.1-1.7,1.6c0,0,0,0.1,0,0.1c-0.2,0.4-0.4,0.7-0.6,1.1c-0.6,1.4-1.8,2.2-3.1,2.6
                c-1.1,7.4-1.3,15-5.7,21.4c-0.3,0.4-0.6,0.8-1,1.1c1.7,3.5-0.1,8.8-5.2,8.8c-4.6,0-9.3,0.2-13.9,0.1c-0.4,0.1-0.8,0.2-1.3,0.3
                c-16.7,2.2-33.6,2.9-50.3,0.1c-2.9-0.5-4.2-2.4-4.3-4.5c-0.8-0.1-1.6-0.5-2.3-0.9c-0.6,0.6-1.4,1.1-2.4,1.4
                c-0.5,0.8-1.1,1.5-2.1,2.1c-6.2,3.9-9.3-14-16.2-14.7c-7.1-0.7-17.5-2.8-24.5-2.5c-1.7,1.3-4.6,20.8-6.6,21.4
                c-0.1,0.7-0.2,1.3-0.5,2c0.1,0.8,0.2,1.5,0.3,2.3c0.2,3.7-1.1,7.2-3.2,10c-1.4,17-2.8,34-5.3,50.9c-1.3,8.7-2.9,17.4-5.5,25.8
                c-1.4,11.7-2.8,23.7-4.2,35.9c-0.7,6.2-1.5,12.3-2.3,18.5c-0.5,3.5-0.9,6.9-1.4,10.4c-0.1,1-0.3,1.9-0.4,2.9
                c-0.1,0.5-0.3,2.3-0.3,2.7c-1.3,11-10.6,32-11.6,42.9c-1.1,12.2,5.9,14.3,3.3,26.3c-1.2,5.6-2.4,11.1-3.4,16.8
                c-0.1,0.4-0.1,0.8-0.2,1.1c0,0.1,0,0.4-0.1,0.8c-0.1,1.2-0.2,2.5-0.3,3.7c-0.1,2.3-0.2,9.2-0.1,11.5c67,0.9,139.8-8.7,215.1-20.4
                c85-13.2,165-40.7,234.5-36.7c-0.2-15.1-1.3-52.2-0.6-67.3c0.8-17.1,1.4-34.3,2-51.4c1.2-34.8,2.1-69.5,2.2-104.3
                c0-0.9,0.1-1.7,0.2-2.5C1652.5,557.1,1649.3,556.8,1646.2,556.5z"
                onClick={() => {
                    props.onClick?.(PERMOFLAGES_CATEGORY);
                }}
                onMouseMove={() => {
                    props.onMouseMove?.(true, PERMOFLAGES_CATEGORY);
                }}
                onMouseLeave={() => {
                    props.onMouseLeave?.(false, PERMOFLAGES_CATEGORY);
                }}
                onMouseEnter={() => {
                    props.onMouseEnter?.(false, PERMOFLAGES_CATEGORY);
                }}
                ref={(_ref) => {
                    ref.current = _ref;
                }}
            />
            <path
                className={classNames(styles.permoMaskSt5, styles.hoverDisabled)}
                d="M1273,806v195h96.1c0.1-15.5,3-31.1,6.4-46.1c1.7-7.5,4-14.8,6.1-22.2c1-3.2,1.8-6.5,2.5-9.8c0-0.3,0-0.7,0-1.3
                c0.1-2-0.1-3.9-0.3-5.9c-0.5-4.1,0.6-8.1,2.6-11.6c-0.4-3.8,0.7-7.6,3.4-10.7c1.1-3.9,6-5.8,9.4-4.2c2.4,0.1,4.4,0.8,6.5,1.8
                c1-0.1,2-0.2,3-0.2c5,0.1,10,0.3,15,0.5c1.4-0.3,2.9-0.4,4.4-0.5c10.3-0.1,21-1.3,31.2,0.6c8.9-0.4,17.8-0.4,26.6,0.4
                c1.4-0.3,2.8-0.4,4.2-0.4c1.4,0,2.7,0.1,4,0.3V806H1273z"
                onClick={() => {
                    props.onClick?.(PERMOFLAGES_CATEGORY);
                }}
                onMouseMove={() => {
                    props.onMouseMove?.(true, PERMOFLAGES_CATEGORY);
                }}
                onMouseLeave={() => {
                    props.onMouseLeave?.(false, PERMOFLAGES_CATEGORY);
                }}
                onMouseEnter={() => {
                    props.onMouseEnter?.(false, PERMOFLAGES_CATEGORY);
                }}
            />
        </React.Fragment>
    );
});

export default CamoSVG;
