import { TAGS } from './consts';
import { Vehicle } from '~/vortex';
import { Item } from '~/interfaces';

export const showPreviewVehicleById = (vehicleId: number, exteriorId: number) => {
    const data = {
        command: 'action',
        params: {
            actionId: 'previewExterior',
            shipId: +vehicleId,
            exteriorId: exteriorId,
        },
    };

    console.log('preview vehicle data', data);

    // @ts-ignore
    window.jsHostQuery && window.jsHostQuery({ request: JSON.stringify(data) });
};

export const getVehicleTypeIcon = (vehicle: Vehicle) => {
    if (vehicle.tags.includes('uiPremium')) {
        return vehicle.type?.icons?.premium;
    } else if (vehicle.tags.includes('uiSpecial')) {
        return vehicle.type?.icons?.special;
    }

    return vehicle.type?.icons?.default;
};

export const isAnime = (item: Item) =>
    item.tags.includes(TAGS.ARPEGGIO) || item.tags.includes(TAGS.CH_DRAGONS) || item.tags.includes(TAGS.CREWS_ANIME) || item.tags.includes(TAGS.AZURLANE) || item.tags.includes(TAGS.HSF);
