import * as React from 'react';
import classNames from 'classnames';
import styles from './Filter.scss';
import DefaultTooltip from '../Tooltip/DefaultTooltip';
import { t } from '~/helpers/localization';
import { ICategories } from '~/interfaces';
import { CREWS_CATEGORY } from '~/Actions/ActionCategory';
import { isAuthorizedUser } from '~/config/user';
import { DISPLAY_NAME_FILTER, getRadioFiltersByCategory } from '~/Components/Filter/filtersMap';
import { DWH_EVENTS, TYPE_ALL, TYPE_IS_RECEIVED, TYPE_IS_NOT_RECEIVED } from '~/helpers/consts';
import FilterCategory from '~/Components/Filter/FilterCategory';
import useClickAway from '~/hooks/useClickAway';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { resetFilters, updateCategoryFilters } from '~/Actions/ActionFilter';
import { IFilters } from '~/Reducers/ReducerFilter';
import { flat } from '~/helpers/array';
import RenderActiveFilters from '~/Components/Filter/RenderActiveFilters';
import { getFiltersStateByCategory } from '~/settings/filters';
import { playDropdownClickSound, playButtonClickSound } from '~/helpers/audioApi';
import dwhExport from '~/helpers/dwhExport';
import { DivTooltip, Radio } from '@wg/wows-react-uikit';

type IProps = {
    category: ICategories;
    availableCount: number;
    filteredAvailableCount?: number;
    filteredItems: any[];
};

interface IDisplayFilter {
    category: ICategories;
}

interface IStateSelector {
    filters: IFilters;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        filters: state.ReducerFilter.filters,
    };
};

const RenderDisplayFilter = (props: IDisplayFilter) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IStateSelector>(stateSelector, shallowEqual);

    if (!isAuthorizedUser()) {
        return null;
    }

    const radioFilters = getRadioFiltersByCategory(props.category);

    return (
        <React.Fragment>
            {radioFilters.map((filterItem, index) => {
                return (
                    <div className={classNames(styles.filterColumn, styles.borderLeft)} key={index}>
                        <div className={styles.filterColumnHeader}>{filterItem.title}</div>
                        <div className={styles.filterColumnBody}>
                            {filterItem.values.map((valueItem) => {
                                let title = valueItem.title;

                                if (filterItem.name === 'display' && props.category === CREWS_CATEGORY) {
                                    if (valueItem.value === TYPE_ALL) {
                                        title = t('Все:командиры');
                                    } else if (valueItem.value === TYPE_IS_RECEIVED) {
                                        title = t('Полученные:командиры');
                                    } else if (valueItem.value === TYPE_IS_NOT_RECEIVED) {
                                        title = t('Не полученные:командиры');
                                    }
                                }

                                const filters = getFiltersStateByCategory(appState.filters[props.category], props.category);
                                const selectedFilters = filters[filterItem.name];
                                const isChecked = selectedFilters.includes(valueItem.value);

                                return (
                                    <div className={styles.filterItem} key={valueItem.value}>
                                        <Radio
                                            value={valueItem.value}
                                            onChange={() => {
                                                if (!isChecked) {
                                                    let type = 'all';
                                                    if (valueItem.value === TYPE_IS_RECEIVED) {
                                                        type = 'recieved';
                                                    } else if (valueItem.value === TYPE_IS_NOT_RECEIVED) {
                                                        type = 'not_recieved';
                                                    }
                                                    dwhExport.send(DWH_EVENTS.APPLY_FILTERS, { category: props.category, name: 'display', value: type });
                                                    dispatch(updateCategoryFilters(props.category, filterItem.name, valueItem.value.toString()));
                                                }
                                            }}
                                            isChecked={isChecked}
                                            name={valueItem.value.toString()}
                                        >
                                            {title}
                                        </Radio>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

const Filter = (props: IProps) => {
    const ref = React.useRef();
    const dispatch = useDispatch();
    const appState = useSelector<State, IStateSelector>(stateSelector, shallowEqual);
    const [isVisible, setVisible] = React.useState<boolean>(false);

    const changeVisible = () => setVisible(!isVisible);

    const _resetFilters = (withoutEvent = false) => {
        !withoutEvent && dwhExport.send(DWH_EVENTS.RESET_FILTERS, { category: props.category });
        dispatch(resetFilters(props.category));
    };

    const isNeedToShowActiveFilter = () => {
        const activeFilters = { ...appState.filters[props.category] };
        delete activeFilters[DISPLAY_NAME_FILTER];
        return !!flat(Object.values(activeFilters)).length;
    };

    const onClick = () => {
        playDropdownClickSound();
        changeVisible();
    };

    useClickAway(ref, () => {
        setVisible(false);
    });

    React.useEffect(() => {
        return () => {
            _resetFilters(true);
        };
    }, []);

    return (
        <div className={styles.filterWrap} ref={ref}>
            <div className={styles.filterHeader}>
                <div className={styles.filterHeaderWrap}>
                    <div className={styles.filterLabel} onClick={onClick}>
                        <div>{t('Фильтр')}</div>
                    </div>
                    {isNeedToShowActiveFilter() && (
                        <React.Fragment>
                            <div className={styles.activeFilters} onClick={onClick}>
                                <RenderActiveFilters filters={appState.filters} category={props.category} />
                            </div>
                            <div
                                className={styles.resetFilters}
                                onClick={() => {
                                    playButtonClickSound();
                                    _resetFilters();
                                }}
                            >
                                {t('Сбросить все')}
                            </div>
                            <div className={styles.filterSearchCounter}>
                                <DivTooltip
                                    tooltipBody={
                                        <DefaultTooltip
                                            title={t('Результаты поиска')}
                                            tableData={{
                                                [t('Получено')]: Math.min(props.filteredAvailableCount ?? props.availableCount, props.filteredItems.length),
                                                [t('Всего')]: props.filteredItems.length,
                                            }}
                                        />
                                    }
                                >
                                    {Math.min(props.filteredAvailableCount ?? props.availableCount, props.filteredItems.length)}/{props.filteredItems.length}
                                </DivTooltip>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                {isVisible && (
                    <div className={styles.filterContainer}>
                        <FilterCategory category={props.category} filteredItems={props.filteredItems} />
                        <RenderDisplayFilter category={props.category} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Filter;
