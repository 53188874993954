import * as React from 'react';

const useClickAway = (ref: React.RefObject<any>, callback?: (event: React.KeyboardEvent) => void): void => {
    function handleClickOutside(event?: React.KeyboardEvent) {
        if (event.which === 1 && ref.current && !ref.current.contains(event.target)) {
            if (typeof callback === 'function') {
                callback(event);
            }
        }
    }

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside.bind(this));

        return () => {
            document.removeEventListener('mousedown', handleClickOutside.bind(this));
        };
    }, []);
};

export default useClickAway;
