import * as React from 'react';
import { _Item, ICategories, IInventoryList, IInventoryState, IResponse, Item } from '~/interfaces';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { INVENTORY_KEY_BY_CATEGORY } from '~/helpers/consts';
import ProgressCollected from '~/Components/Profile/ProgressCollected';
import CategoryLayout from '~/Layouts/Category/CategoryLayout';
import { IGlobalFilters } from '~/Reducers/ReducerFilter';
import { isAnime } from '~/helpers/vehiclesApi';

interface IContainerCategory {
    category: ICategories;
}

interface IState {
    inventory: IInventoryState;
    response: IResponse;
    searchResults: any;
    filteredItems: _Item[];
    globalFilters: IGlobalFilters;
}

const stateSelector = (state: State): IState => {
    return {
        inventory: state.ReducerApp.inventory,
        response: state.ReducerApp.response,
        searchResults: state.ReducerCategory.search,
        filteredItems: state.ReducerFilter.filteredItems,
        globalFilters: state.ReducerFilter.globalFilters,
    };
};

const ContainerCategory = (props: IContainerCategory) => {
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const inventoryKey = INVENTORY_KEY_BY_CATEGORY[props.category] as IInventoryList;
    const inventoryItems = appState.inventory[inventoryKey];
    const progressCollected = new ProgressCollected(appState.response, appState.inventory);

    let filteredTotalCount: number | undefined;

    let items = appState.response[props.category] as Item[];
    if (appState.searchResults?.results) {
        items = appState.searchResults?.results;
    } else if (appState.filteredItems) {
        items = appState.filteredItems;

        if (inventoryItems) {
            filteredTotalCount = appState.filteredItems.filter((item) => {
                return 'id' in item ? inventoryItems.indexOf(item['id'] as string) > -1 : false;
            }).length;
        }
    } else if (appState.globalFilters?.author_content === false) {
        items = items.filter((item) => !isAnime(item as Item));
    }

    const intersectionObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const target = entry.target as HTMLElement;
                target.style.backgroundImage = `url(${target.dataset.background})`;
                target.classList.remove('lazy');
                observer.unobserve(target);
            }
        });
    });

    React.useEffect(() => {
        [...document.querySelectorAll('.lazy')].forEach((item) => {
            intersectionObserver.observe(item);
        });

        return () => {
            intersectionObserver.disconnect();
        };
    }, [items]);

    const _props = {
        category: props.category,
        availableAwardsCount: progressCollected.getAvailableCountByKey(inventoryKey, props.category, props.category),
        totalCount: progressCollected.getTotalCountByKey(props.category, false),
        filteredTotalCount: filteredTotalCount,
        uniqueAwardsCount: progressCollected.getTotalUniqueCountByKey(props.category),
        rareAwardsCount: progressCollected.getTotalRareCountByKey(props.category),
        items: items,
        inventoryItems: inventoryItems,
    };

    return <CategoryLayout {..._props} key={props.category} />;
};

export default ContainerCategory;
