import * as React from 'react';
import { Nation, VehicleType } from '~/vortex';
import { State } from '~/Reducers';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '~/Components/Filter/Filter.scss';
import { FILTER_TITLE } from '~/helpers/consts';
import { NAME_BACKGROUND_FILTER, NAME_PATCH_FILTER, NAME_SYMBOL_FILTER } from '~/Actions/ActionFilter';
import { t } from '~/helpers/localization';
import {
    CREW_CATEGORY_NAME_FILTER,
    CREW_TYPE_NAME_FILTER,
    DOLL_CATEGORY_NAME_FILTER,
    ENSIGN_CATEGORY_NAME_FILTER,
    FILTERS_NAMES,
    NATION_NAME_FILTER,
    TYPE_SHIP_NAME_FILTER,
} from '~/Components/Filter/filtersMap';

interface IRenderFilterByType {
    filterName: FILTERS_NAMES;
    value: string;
}

interface IRenderFilterState {
    vehicleTypes: VehicleType[];
    nations: Nation[];
}

const stateSelectorRenderFilter = (state: State): IRenderFilterState => {
    return {
        vehicleTypes: state.ReducerApp.response.vehicleTypes,
        nations: state.ReducerApp.response.nations,
    };
};

const getItemByKey = (arr: any[], key: string, value: any) => {
    return arr.filter((item) => item[key] === value)[0];
};

const RenderFilterByType = ({ filterName, value }: IRenderFilterByType) => {
    const appState = useSelector<State, IRenderFilterState>(stateSelectorRenderFilter, shallowEqual);

    switch (filterName) {
        case NATION_NAME_FILTER:
            const nation = getItemByKey(appState.nations, 'name', value);
            return (
                <div
                    className={styles.activeFilterNation}
                    style={{
                        backgroundImage: `url(${nation.icons.tiny})`,
                    }}
                />
            );

        case TYPE_SHIP_NAME_FILTER:
            const shipType = getItemByKey(appState.vehicleTypes, 'name', value);
            return (
                <div
                    className={styles.activeFilterType}
                    style={{
                        backgroundImage: `url(${shipType.icons.default})`,
                    }}
                />
            );

        case ENSIGN_CATEGORY_NAME_FILTER:
        case CREW_CATEGORY_NAME_FILTER:
        case CREW_TYPE_NAME_FILTER:
            return <div className={styles.activeItemFilter}>{FILTER_TITLE[value]}</div>;

        case DOLL_CATEGORY_NAME_FILTER:
            let type;
            if (value === NAME_SYMBOL_FILTER) {
                type = t('Символ');
            } else if (value === NAME_BACKGROUND_FILTER) {
                type = t('Форма');
            } else if (value === NAME_PATCH_FILTER) {
                type = t('Нашивка');
            }

            return <div className={styles.activeItemFilter}>{type}</div>;

        default:
            return null;
    }
};

export default RenderFilterByType;
