import DwhExportLib, { DwhExportMeta } from '@wg/dwh-export-lib';
import { account } from './utils';

class DwhExport {
    static readonly PROJECT = 'catalogue';

    static readonly INTERVAL = 3;

    static readonly MAX_EVENT_BATCH_SIZE = 5;

    private readonly url: string = null;

    private readonly accountId: number = null;

    private readonly DwhExportLib: DwhExportLib;

    constructor() {
        this.url = window.catalogue.urls.dwhExport;
        this.accountId = account?.id;
        this.DwhExportLib = new DwhExportLib({
            url: this.url,
            exportInterval: DwhExport.INTERVAL,
            maxEventsBatchSize: DwhExport.MAX_EVENT_BATCH_SIZE,
            project: DwhExport.PROJECT,
        });
        this.DwhExportLib.start();
    }

    isAllowedEvents() {
        if (!this.url) {
            return false;
        }

        if (!this.accountId) {
            return false;
        }

        return true;
    }

    appStart() {
        this.isAllowedEvents() && this.DwhExportLib.appStart();
    }

    send = (event: string, meta?: DwhExportMeta, force = false) => {
        if (!this.isAllowedEvents()) {
            return;
        }

        meta = meta || {};

        try {
            this.DwhExportLib.push(event, <DwhExportMeta>meta);

            if (force) {
                this.DwhExportLib.send();
            }
        } catch (e) {
            console.warn('Stats export failed', e);
        }
    }
}

export default new DwhExport();
