export const isTestPage = () => {
    return document.location.host.includes('pages.ix') || document.location.host.includes('cadtalogue.portal.loc');
};

export const getTestId = () => 1032590904;

export const isAuthorizedUser = () => {
    const account = window.catalogue.state.account;
    if (isTestPage()) {
        return false;
    }

    return !!account?.id;
};

export const getUserId = () => {
    const account = window.catalogue.state.account;
    if (isTestPage() && !account?.id) {
        return getTestId();
    }

    return isAuthorizedUser() ? account.id : null;
};
