import { ReactNode, RefObject, useRef } from "react";
import useLazyRendering from "~/hooks/useLazyRendering";

interface ILazyElement {
    className?: string;
    children: ReactNode;
    parentRef: RefObject<HTMLDivElement>;
}

export default function LazyElement({ className, children, parentRef }: ILazyElement) {
    const elementRef = useRef<HTMLDivElement>();
    const isVisible = useLazyRendering(elementRef, parentRef);

    return (
        <div ref={elementRef} className={className}>{isVisible && children}</div>
    );
}
