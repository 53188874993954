import * as React from 'react';
import styles from '~/Components/Profile/Profile.scss';
import { isAuthorizedUser } from '~/config/user';
import classNames from 'classnames';
import { t } from '~/helpers/localization';
import { IProgressSVG } from '~/Components/Profile/SvgRoom';

const DollsProgressBar = React.forwardRef((props: IProgressSVG, ref: any) => {
    const rectRef = React.useRef<SVGRectElement>();

    React.useEffect(() => {
        if (!rectRef.current) {
            return;
        }

        const pathVehicleLength = rectRef.current.width.baseVal.value;
        const svgVehiclePathTo = Math.floor((pathVehicleLength * props.progressCollected.getPercentProgressDoll()) / 100);

        rectRef.current.style.width = `${svgVehiclePathTo}px`;
    }, []);

    if (!isAuthorizedUser()) {
        return null;
    }

    return (
        <svg width="2500" height="1406" viewBox="0 0 2500 1406" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.svgProgress)} ref={ref}>
            <g filter="url(#dollsProgressG)">
                <path d="M1384.48 971L1693 983.093V1051L1384.48 1034L1372 1012.86L1384.48 971Z" fill="black" fillOpacity="0.4" />
            </g>
            <path
                opacity="0.5"
                d="M1385.01 1009.5L1384.5 1009.49V1010V1020V1020.49L1384.99 1020.5L1692.99 1029.5L1693.5 1029.51V1029V1019V1018.51L1693.01 1018.5L1385.01 1009.5Z"
                fill="black"
                stroke="white"
            />
            <mask id="dollsProgressMask" mask-type="alpha" maskUnits="userSpaceOnUse" x="1385" y="1010" width="308" height="19">
                <path d="M1385 1010L1693 1019V1029L1385 1020V1010Z" fill="#FFCC00" />
            </mask>
            <g mask="url(#dollsProgressMask)">
                <rect x="1384.26" y="978" width="308.744" height="52" fill="#FFCC00" ref={rectRef} />
            </g>
            <defs>
                <filter id="dollsProgressG" x="1332" y="931" width="401" height="160" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="20" result="effect1_foregroundBlur" />
                </filter>
            </defs>
            <text x={'1418'} y={'946'} className={classNames(styles.textProgressBar, styles.textProgressBarDolls)}>
                {t('Знаки различия')} {props.progressCollected.getAvailableDollsCount()}/{props.progressCollected.getTotalDollsCount()}
            </text>
        </svg>
    );
});

export default DollsProgressBar;
