import { GLOBAL_FILTERS_NAMES } from '~/Components/AdditionalFilter/globalFiltersMap';
import { _Item, ICategories } from '~/interfaces';
import { FILTERS_NAMES } from '~/Components/Filter/filtersMap';
import { Dispatch } from 'redux';
import { State } from '~/Reducers';
import { IChangeGlobalFilter, IFilterItemsByUniqueType, IResetFilters, IUpdateCategoryFilters, UNIQUE_TYPES } from '~/Actions/ActionFilterTypes';

export const ACTION_TYPE_CHANGE_VISIBLE = 'ACTION_TYPE_CHANGE_VISIBLE';
export const ACTION_TYPE_LOADED_FILTERS = 'ACTION_TYPE_LOADED_FILTERS';
export const ACTION_TYPE_CHANGE_FILTER = 'ACTION_TYPE_CHANGE_FILTER';
export const ACTION_TYPE_RESET_FILTERS = 'ACTION_TYPE_RESET_FILTERS';
export const ACTION_TYPE_FACET_FILTER = 'ACTION_TYPE_FACET_FILTER';
export const ACTION_TYPE_CHANGE_VISIBLE_SELECT = 'ACTION_TYPE_CHANGE_VISIBLE_SELECT';
export const ACTION_TYPE_CHANGE_RADIO_FILTER = 'ACTION_TYPE_CHANGE_RADIO_FILTER';
export const ACTION_TYPE_CHANGE_VISIBLE_HAVE_FILTER = 'ACTION_TYPE_CHANGE_VISIBLE_HAVE_FILTER';
export const ACTION_TYPE_CHANGE_GLOBAL_FILTERS = 'ACTION_TYPE_CHANGE_GLOBAL_FILTERS';
export const ACTION_UPDATE_CATEGORY_FILTERS = 'ACTION_UPDATE_CATEGORY_FILTERS';
export const ACTION_FILTER_ITEMS_BY_UNIQUE_TYPE = 'ACTION_FILTER_ITEMS_BY_UNIQUE_TYPE';

export const NAME_FILTER_NATIONS = 'nations';
export const NAME_FILTER_TYPES = 'types';
export const NAME_FILTER_LEVELS = 'levels';
export const NAME_FILTER_DEFAULT = 'default';
export const CREW_CATEGORY_FILTER = 'category';
export const CREW_TALENTS_FILTER = 'talents';
export const NAME_FILTER_DEFAULT_RESET = 'default_reset';
export const NAME_SYMBOL_FILTER = 'symbol';
export const NAME_BACKGROUND_FILTER = 'background';
export const NAME_PATCH_FILTER = 'patch';
export const TYPE_ITEMS_RARE = 'rare';
export const TYPE_ITEMS_SPECIAL = 'special';

export const TYPE_ITEMS_COMMON = 'common';

export type ActionAppType = 'Filter_INIT';
export const resetFilters = (categoryName: ICategories): IResetFilters => {
    return {
        type: ACTION_TYPE_RESET_FILTERS,
        categoryName,
    };
};

export const updateCategoryFilters = (category: ICategories, filterKey: FILTERS_NAMES, value: string) => {
    return (dispatch: Dispatch<IUpdateCategoryFilters>, getState: () => State) => {
        const items = getState().ReducerApp.response[category] as _Item[];
        const filtersMap = getState().ReducerApp.filtersMap;

        dispatch({
            type: ACTION_UPDATE_CATEGORY_FILTERS,
            category,
            key: filterKey,
            value,
            items,
            filtersMap,
        });
    };
};

export const filterItemsByUniqueType = (category: ICategories, uniqueType: UNIQUE_TYPES) => {
    return (dispatch: Dispatch, getState: () => State) => {
        const items = getState().ReducerApp.response[category] as _Item[];

        dispatch<IResetFilters>({
            type: ACTION_TYPE_RESET_FILTERS,
            categoryName: category,
        });

        dispatch<IFilterItemsByUniqueType>({
            type: ACTION_FILTER_ITEMS_BY_UNIQUE_TYPE,
            uniqueType,
            category,
            items,
        });
    };
};

export const changeGlobalFilters = (category: ICategories, key: GLOBAL_FILTERS_NAMES, value: boolean) => {
    return (dispatch: Dispatch<IChangeGlobalFilter>, getState: () => State) => {
        const items = getState().ReducerApp.response[category] as _Item[];

        dispatch({
            type: ACTION_TYPE_CHANGE_GLOBAL_FILTERS,
            key,
            value,
            items,
            category,
        });
    };
};
