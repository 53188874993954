import { ActionType } from '~/Actions';
import { State } from '~/Reducers/index';
import { TYPE_ALL } from '~/helpers/consts';
import { getFilterByKey } from '~/Components/Filter/filtersMap';
import { _Item } from '~/interfaces';
import { filterItems, filterItemsByUniqueType, getFacetFilters } from '~/settings/filters';
import { ACTION_FILTER_ITEMS_BY_UNIQUE_TYPE, ACTION_TYPE_CHANGE_GLOBAL_FILTERS, ACTION_TYPE_RESET_FILTERS, ACTION_UPDATE_CATEGORY_FILTERS } from '~/Actions/ActionFilter';
import { UNIQUE_TYPES } from '~/Actions/ActionFilterTypes';

export interface IVehiclesFilters {
    display?: string[];
    nation: string[];
    type_ship: string[];
    level_ship: string[];
}

export interface ICrewsFilters {
    display?: string[];
    nation: string[];
    crew_category: string[];
    crew_type: string[];
}

export interface ICamoFilters {
    display?: string[];
    nation: string[];
    type_ship: string[];
    level_ship: string[];
}

export interface IEnsignsFilters {
    display?: string[];
    ensign_category: string[];
}

export interface IDollsFilters {
    display?: string[];
    doll_category: string[];
}

export type IFilterTypes = IVehiclesFilters | ICrewsFilters | ICamoFilters | IEnsignsFilters | IDollsFilters;

export interface IFilters {
    vehicles: IVehiclesFilters;
    crews: ICrewsFilters;
    permoflages: ICamoFilters;
    ensigns: IEnsignsFilters;
    dogTagComponents: IDollsFilters;
}

export interface IGlobalFilters {
    author_content: boolean;
}

export type FilterState = {
    globalFilters: IGlobalFilters;
    filters: IFilters;
    facetFilters?: IFilters;
    filteredItems?: _Item[];
    selectedType: UNIQUE_TYPES;
};

const initialState: FilterState = {
    globalFilters: {
        author_content: true,
    },
    selectedType: null,
    filters: {
        vehicles: {
            display: [TYPE_ALL],
            nation: [],
            type_ship: [],
            level_ship: [],
        },
        crews: {
            display: [TYPE_ALL],
            nation: [],
            crew_category: [],
            crew_type: [],
        },
        permoflages: {
            display: [TYPE_ALL],
            nation: [],
            type_ship: [],
            level_ship: [],
        },
        ensigns: {
            display: [TYPE_ALL],
            ensign_category: [],
        },
        dogTagComponents: {
            display: [TYPE_ALL],
            doll_category: [],
        },
    },
    facetFilters: {
        vehicles: {
            nation: [],
            type_ship: [],
            level_ship: [],
        },
        crews: {
            nation: [],
            crew_category: [],
            crew_type: [],
        },
        permoflages: {
            nation: [],
            type_ship: [],
            level_ship: [],
        },
        ensigns: {
            ensign_category: [],
        },
        dogTagComponents: {
            doll_category: [],
        },
    },
};

export type IReducerFilter = (state: FilterState, action: ActionType) => State;
export const ReducerFilter = (state: FilterState = initialState, action: ActionType) => {
    switch (action.type) {
        case ACTION_TYPE_CHANGE_GLOBAL_FILTERS:
            const newGlobalFilters = {
                ...state.globalFilters,
                [action.key]: action.value,
            };

            return {
                ...state,
                globalFilters: newGlobalFilters,
                filteredItems: filterItems(state.filters[action.category], newGlobalFilters, filterItemsByUniqueType(action.items, state.selectedType), action.category),
            };

        case ACTION_TYPE_RESET_FILTERS:
            const prevStateCategoryFilters = state.filters[action.categoryName];
            const newStateCategoryFilters: any = Object.keys(prevStateCategoryFilters).reduce((prevState: any, filterName) => {
                if (filterName === 'display') {
                    prevState[filterName] = [TYPE_ALL];
                } else {
                    prevState[filterName] = [];
                }

                return prevState;
            }, {});

            const facetData = Object.keys(state.facetFilters[action.categoryName]).reduce((prevState: any, filterName) => {
                prevState[filterName] = [];

                return prevState;
            }, {});

            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.categoryName]: newStateCategoryFilters,
                },
                facetFilters: {
                    ...state.facetFilters,
                    [action.categoryName]: facetData,
                },
                filteredItems: null,
                selectedType: null,
            };

        case ACTION_UPDATE_CATEGORY_FILTERS:
            const currentState: any = state.filters[action.category] || {};
            const filterSettings = getFilterByKey(action.key);
            let facetFilters = state.facetFilters[action.category];

            if (filterSettings.type === 'radio') {
                currentState[action.key] = [action.value];
            } else {
                if (!currentState[action.key]) {
                    currentState[action.key] = [action.value];
                } else {
                    if (currentState[action.key].includes(action.value)) {
                        currentState[action.key] = currentState[action.key].filter((value: string) => value !== action.value);
                    } else {
                        currentState[action.key] = [...currentState[action.key], action.value];
                    }
                }

                facetFilters = getFacetFilters(currentState, state.facetFilters, action.category, action.key, action.items, action.filtersMap);
            }

            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.category]: {
                        ...currentState,
                    },
                },
                facetFilters: {
                    ...state.facetFilters,
                    [action.category]: facetFilters,
                },
                filteredItems: filterItems(currentState, state.globalFilters, action.items, action.category),
            };

        case ACTION_FILTER_ITEMS_BY_UNIQUE_TYPE:
            return {
                ...state,
                selectedType: action.uniqueType,
                filteredItems: filterItems(state.filters[action.category] || {}, state.globalFilters, filterItemsByUniqueType(action.items, action.uniqueType), action.category),
            };

        default:
            return state;
    }
};
