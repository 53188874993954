import { isAuthorizedUser } from '~/config/user';
import { ACTION_TYPE_CHANGE_SLIDE, ACTION_TYPE_DIALOG_LOADER, ACTION_TYPE_FINISH_LOADED_INFO, ACTION_TYPE_LOADED_INFO_ERROR, ACTION_TYPE_SET_DEV_MODE } from '~/Actions/ActionApp';
import { ActionType } from '~/Actions';
import { IInventoryState, IResponse } from '~/interfaces';
import { State } from '~/Reducers/index';
import { IMaps, prepareMaps } from '~/Components/Filter/filtersMap';
import { postPrepareResponse, prepareResponse, prepareResponseFromFilters } from '~/settings/response';
import { prepareInventory } from '~/settings/inventory';

export type AppState = {
    response: IResponse;
    inventory?: IInventoryState;
    promoWidget?: any;
    isErrorLoad?: boolean;
    isAuthorizedUser?: boolean;
    filtersMap: IMaps[];
};

const initialState: AppState = {
    response: null,
    inventory: null,
    filtersMap: [],
};

export type IReducerApp = (state: AppState, action: ActionType) => State;
export const ReducerApp = (state: AppState = initialState, action: ActionType) => {
    switch (action.type) {
        case ACTION_TYPE_FINISH_LOADED_INFO:
            const data = prepareResponse(action.response, { ...action.inventory });
            const storage = action.inventory ? prepareInventory(action.inventory, data) : null;
            const { response, inventory } = postPrepareResponse(data, storage);

            return Object.assign({}, state, {
                response: {
                    ...prepareResponseFromFilters(response, inventory),
                },
                inventory: {
                    ...inventory,
                },
                isAuthorizedUser: isAuthorizedUser(),
                isErrorLoad: false,
                filtersMap: prepareMaps(response),
            });

        case ACTION_TYPE_DIALOG_LOADER:
            return {
                ...state,
                dialogLoader: action.isVisible,
            };

        case ACTION_TYPE_LOADED_INFO_ERROR:
            return {
                ...state,
                isErrorLoad: true,
                response: action.response,
            };

        case ACTION_TYPE_CHANGE_SLIDE:
            return {
                ...state,
                promoWidget: {
                    ...state.promoWidget,
                    slide: action.slide,
                    isFinish: action.isFinish,
                },
            };

        case ACTION_TYPE_SET_DEV_MODE:
            return {
                ...state,
                isDevMode: action.isDevMode,
            };

        default:
            return state;
    }
};
