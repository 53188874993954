import * as React from 'react';
import { account, isNeedToShownWelcomePage, setLocalKeyForWelcomePageByProfileId } from '~/helpers/utils';
import View from '~/Components/View/View';
import PromoWidget from '~/Components/PromoWidget/PromoWidget';
import { promoWidgetTypes } from '~/helpers/consts';

interface IContainerView {
    isNeedToShowWelcomePage?: boolean;
}

const ContainerView = (props: IContainerView) => {
    if (isNeedToShownWelcomePage(account?.id) && props.isNeedToShowWelcomePage) {
        return (
            <PromoWidget
                type={promoWidgetTypes.welcome}
                isRenderCloseButton={true}
                onFinish={() => {
                    setLocalKeyForWelcomePageByProfileId(account?.id);
                }}
            />
        );
    }

    return <View />;
};

export default ContainerView;
