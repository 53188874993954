import * as React from 'react';
import classNames from 'classnames';
import styles from './Profile.scss';
import { PERMOFLAGES_CATEGORY } from '~/Actions/ActionCategory';
import ProgressCollected from '~/Components/Profile/ProgressCollected';
import { imagesRoomLocalPath, isInGameBrowser } from '~/helpers/utils';

interface IRoomContent {
    progressCollected: ProgressCollected;
    isFirstDisplay?: boolean;
}

const RoomContent = (props: IRoomContent) => {
    const getBackgroundStyle = (percent: number) => (percent && props.isFirstDisplay ? { opacity: 0 } : null);

    const vehiclesPercent = props.progressCollected.getPercentProgressVehicle(true);
    const vehiclesPercentDisplay = props.progressCollected.getPercentForDisplay(vehiclesPercent, props.progressCollected.getAvailableVehiclesCount(true));

    const vehicleClassName = `vehicleItem-${vehiclesPercentDisplay}`;
    const vehicleClassNames = classNames(styles.backgroundItem, {
        [styles[vehicleClassName]]: !isInGameBrowser,
    });
    const vehicleStyles = isInGameBrowser
        ? {
              backgroundImage: `url(${imagesRoomLocalPath}/images/vehicles/vehicles-${vehiclesPercentDisplay}.png)`,
          }
        : {};

    const crewPercent = props.progressCollected.getPercentProgressCrew(true);
    const crewPercentDisplay = props.progressCollected.getPercentForDisplay(crewPercent, props.progressCollected.getAvailableCrewCount(true));

    const crewClassName = `crewItem-${crewPercentDisplay}`;
    const crewClassNames = classNames(styles.backgroundItem, {
        [styles[crewClassName]]: !isInGameBrowser,
    });
    const crewStyles = isInGameBrowser
        ? {
              backgroundImage: `url(${imagesRoomLocalPath}/images/crews/crews-${crewPercentDisplay}.png)`,
          }
        : {};

    const dollsPercent = props.progressCollected.getPercentProgressDoll(true);
    const dollsPercentDisplay = props.progressCollected.getPercentForDisplay(dollsPercent, props.progressCollected.getAvailableDollsCount(true));

    const dollClassName = `dollsItem-${dollsPercentDisplay}`;
    const dollsClassNames = classNames(styles.backgroundItem, {
        [styles[dollClassName]]: !isInGameBrowser,
    });
    const dollsStyles = isInGameBrowser
        ? {
              backgroundImage: `url(${imagesRoomLocalPath}/images/dolls/dolls-${dollsPercentDisplay}.png)`,
          }
        : {};

    const ensignPercent = props.progressCollected.getPercentProgressEnsign(true);
    const ensignPercentDisplay = props.progressCollected.getPercentForDisplay(ensignPercent, props.progressCollected.getAvailableEnsignsCount(true));

    const ensignClassName = `ensignsItem-${ensignPercentDisplay}`;
    const ensignsClassNames = classNames(styles.backgroundItem, {
        [styles[ensignClassName]]: !isInGameBrowser,
    });
    const ensignsStyles = isInGameBrowser
        ? {
              backgroundImage: `url(${imagesRoomLocalPath}/images/ensigns/ensigns-${ensignPercentDisplay}.png)`,
          }
        : {};

    const permoflagesPercent = props.progressCollected.getPercentProgressPermoflage(true);
    const permoflagesPercentDisplay = props.progressCollected.getPercentForDisplay(permoflagesPercent, props.progressCollected.getAvailablePermoflagesCount(true));

    const permoflagesClassName = `permoflagesItem-${permoflagesPercentDisplay}`;
    const permoflagesClassNames = classNames(styles.backgroundItem, {
        [styles[permoflagesClassName]]: !isInGameBrowser,
    });
    const permoflagesStyles = isInGameBrowser
        ? {
              backgroundImage: `url(${imagesRoomLocalPath}/images/permoflages/permoflages-${permoflagesPercentDisplay}.png)`,
          }
        : {};

    return (
        <React.Fragment>
            <div className={vehicleClassNames} style={{ ...vehicleStyles, ...getBackgroundStyle(vehiclesPercent) }} />
            <div className={crewClassNames} style={{ ...crewStyles, ...getBackgroundStyle(crewPercent) }} />
            <div className={dollsClassNames} style={{ ...dollsStyles, ...getBackgroundStyle(dollsPercent) }} />
            <div className={ensignsClassNames} style={{ ...ensignsStyles, ...getBackgroundStyle(ensignPercent) }} />
            <div className={permoflagesClassNames} data-bg-category={PERMOFLAGES_CATEGORY} style={{ ...permoflagesStyles, ...getBackgroundStyle(permoflagesPercent) }} />
        </React.Fragment>
    );
};

export default RoomContent;
