import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    dogTagComponents(lang: "{{ languageCode }}", isCatalogue: true) {
        id
        name
        title
        type
        color
        quest
        condition
        isColorizable
        tags
        icons {
          localSmall
          small
          large
        }
        textureData {
            id
            background {
                localSmall
                small
                large
            }
            border {
                localSmall
                small
                large
            }
        }
    }
}
`;
