import * as React from 'react';
import styles from './Tooltip.scss';
import { TooltipFooter } from '@wg/wows-react-uikit';

interface IProps {
    title?: string;
    text?: string;
    footer?: string;
    tableData?: any;
}

const DefaultTooltip = (props: IProps) => {
    return (
        <React.Fragment>
            <div className={styles.defaultTooltip}>
                {props.title && <div className={styles.defaultTooltipHeader}>{props.title}</div>}
                {props.text && <div className={styles.defaultTooltipBody}>{props.text}</div>}
                {props.tableData && (
                    <div className={styles.tableData}>
                        {Object.keys(props.tableData).map((key, index) => {
                            return (
                                <div className={styles.tableCell} key={index}>
                                    <div className={styles.tableCellTitle}>{key}</div>
                                    <div className={styles.tableCellInfo}>{props.tableData[key]}</div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className={styles.footer}>{props.footer && <TooltipFooter mouseIcon={'left'}>{props.footer}</TooltipFooter>}</div>
        </React.Fragment>
    );
};

export default DefaultTooltip;
