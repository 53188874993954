import { tpl } from '~/helpers/tpl';

export const query = tpl`
    {
        vehicleTypes (lang: "{{ languageCode }}") {
            name,
            title,
            icons {
                default
            }
        }
        
        nations (lang: "{{ languageCode }}") {
            title
            name
            icons {
                small
                default
                tiny
            }
        }
    }
`;
