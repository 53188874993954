import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    vehicles (lang: "{{ languageCode }}", isCatalogue: true) {
        id 
        title
        titleShort
        level
        isPremium
        isSpecial
        description
        tags
        nation {
            name
            icons {
                small
                tiny
                large
            }
        }
        icons {
            small
            large
            medium
        }
        type {
            icons {
                default
                special
                premium
            }
            name
            title
        }
        ttc {
            title
            description
            unit
            name
            value
        }
    }
}
`;
