import * as React from 'react';
import styles from '~/Components/Profile/Profile.scss';
import { DOLLS_CATEGORY } from '~/Actions/ActionCategory';
import { IItemSVG } from '~/Components/Profile/SvgRoom';

const DollsSVG = React.forwardRef((props: IItemSVG, ref: any) => {
    return (
        <g
            className={styles.svgG}
            onClick={() => {
                props.onClick?.(DOLLS_CATEGORY);
            }}
            onMouseMove={() => {
                props.onMouseMove?.(true, DOLLS_CATEGORY);
            }}
            onMouseLeave={() => {
                props.onMouseLeave?.(false, DOLLS_CATEGORY);
            }}
            onMouseEnter={() => {
                props.onMouseEnter?.(false, DOLLS_CATEGORY);
            }}
            ref={(_ref) => {
                ref.current = _ref;
            }}
        >
            <mask id="maskVehicles" mask-type="alpha" maskUnits="userSpaceOnUse" x="1374" y="800" width="333" height="248">
                <path
                    d="M1493.5 893.6L1391.2 892.1L1390.1 896.9C1389.5 899.5 1385.6 917.4 1381.5 936.7L1374 971.7V1004V1036.3L1374.5 1039C1384.6 1039 1655.7 1045.7 1687 1047.2C1697.2 1047.7 1705.6 1048 1705.7 1047.9C1706.3 1047.4 1707 983.8 1706.5 980.5C1705.8 976.5 1682.7 899.6 1682 899C1681.8 898.8 1682.1 898.8 1633.5 898C1590 896.5 1584.7 896.2 1584.5 896C1584.3 895.8 1584.4 876.4 1584.5 850.5V801.5L1559.5 801C1550.3 800.8 1538.2 800.6 1523 800.5L1494 800V837"
                    fill="white"
                    fillOpacity="0.8"
                />
            </mask>
            <g mask="url(#maskVehicles)">
                <path
                    d="M1385 916L1391 892L1393.5 890L1491.5 893L1493 799.5L1585 801V895L1682 897.5L1705 965.5L1708.5 980L1707 1049L1579 1046L1372 1040.5V972L1385 916Z"
                    fill="url(#paint0_linear_vehicles)"
                />
            </g>
            <defs>
                <linearGradient id="paint0_linear_vehicles" x1="1540.25" y1="799.5" x2="1540.25" y2="1049" gradientUnits="userSpaceOnUse">
                    <stop offset="0.176132" stopColor="rgba(255, 255, 255, .7)" />
                    <stop offset="0.422629" stopColor="rgba(255, 255, 255, .7)" />
                    <stop offset="0.862351" stopColor="rgba(255, 255, 255, 0)" />
                </linearGradient>
            </defs>
        </g>
    );
});

export default DollsSVG;
