import * as React from 'react';
import styles from '~/Components/Profile/Profile.scss';
import { isAuthorizedUser } from '~/config/user';
import classNames from 'classnames';
import { t } from '~/helpers/localization';
import { IProgressSVG } from '~/Components/Profile/SvgRoom';

const VehicleProgressBar = React.forwardRef((props: IProgressSVG, ref: any) => {
    const rectRef = React.useRef<SVGRectElement>();

    React.useEffect(() => {
        if (!rectRef.current) {
            return;
        }

        const pathVehicleLength = rectRef.current.width.baseVal.value;
        const svgVehiclePathTo = Math.floor((pathVehicleLength * props.progressCollected.getPercentProgressVehicle()) / 100);

        rectRef.current.style.width = `${svgVehiclePathTo}px`;
    }, []);

    if (!isAuthorizedUser()) {
        return null;
    }

    return (
        <svg width="2500" height="1406" viewBox="0 0 2500 1406" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.svgProgress} ref={ref}>
            <g filter="url(#vehiclesProgressG)">
                <path d="M333 621L1161.5 634V675L343.5 707L299.5 666L333 621Z" fill="black" fillOpacity="0.3" />
            </g>
            <path opacity="0.5" d="M340.986 674.5L340.5 674.513V675V685V685.514L341.014 685.5L1155.01 663.5L1155.5 663.487V663V653V652.486L1154.99 652.5L340.986 674.5Z" fill="black" stroke="white" />
            <mask id="vehiclesProgressMask" mask-type="alpha" maskUnits="userSpaceOnUse" x="341" y="653" width="814" height="32">
                <path d="M341 675L1155 653V663L341 685V675Z" fill="#FFCC00" />
            </mask>
            <g mask="url(#vehiclesProgressMask)">
                <rect x="339.002" y="653" width="815.998" height="32" fill="#FFCC00" ref={rectRef} />
            </g>
            <defs>
                <filter id="vehiclesProgressG" x="259.5" y="581" width="942" height="166" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="20" result="effect1_foregroundBlur" />
                </filter>
            </defs>
            <text x={'316'} y={'665'} className={classNames(styles.textProgressBar, styles.textProgressBarVehicles)}>
                {t('Корабли')} {props.progressCollected.getAvailableVehiclesCount()}/{props.progressCollected.getTotalVehiclesCount()}
            </text>
        </svg>
    );
});

export default VehicleProgressBar;
