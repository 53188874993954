import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { settings } from '~/helpers/utils';

const language = settings.languageCode;
const catalog = window.i18n?.catalog || {};
const i18nextTranslations: any = {};

Object.keys(catalog).forEach((key) => {
    const translations = catalog[key];

    if (Array.isArray(translations)) {
        translations.forEach((translation, index) => {
            i18nextTranslations[`${key}_${index}`] = translation;
        });
    } else {
        i18nextTranslations[key] = translations;
    }
});

const i18nextResources = {
    [language]: {
        catalogue: i18nextTranslations,
    },
};

i18n.use(initReactI18next).init({
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: false,
    lowerCaseLng: true,

    lng: language,

    resources: i18nextResources,
    ns: ['catalogue'],
    defaultNS: 'catalogue',

    debug: false,

    interpolation: {
        prefix: '%(',
        suffix: ')s',
        escapeValue: false,
    },
});

export default i18n;
