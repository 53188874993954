import * as React from 'react';
import styles from '~/Components/Profile/Profile.scss';
import { CREWS_CATEGORY } from '~/Actions/ActionCategory';
import { IItemSVG } from '~/Components/Profile/SvgRoom';

const CrewsSVG = React.forwardRef((props: IItemSVG, ref: any) => {
    return (
        <React.Fragment>
            <defs>
                <filter id="crewsMaskFilter" filterUnits="userSpaceOnUse" x="1837.7" y="178.6" width="2500" height="1406">
                    <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="1837.7" y="178.6" width="2500" height="1406" id="crewsMask">
                <g className={styles.st0}>
                    <linearGradient
                        id="crewsMaskGradient"
                        gradientUnits="userSpaceOnUse"
                        x1="1722.7039"
                        y1="111.1108"
                        x2="2184.7773"
                        y2="573.1843"
                        gradientTransform="matrix(0.9285 -0.3713 0.3387 0.847 42.9604 769.176)"
                    >
                        <stop offset="0.176132" stopColor="rgba(255, 255, 255, .7)" />
                        <stop offset="0.422629" stopColor="rgba(255, 255, 255, .7)" />
                        <stop offset="0.862351" stopColor="rgba(255, 255, 255, 0)" />
                    </linearGradient>
                    <path
                        className={styles.st1}
                        d="M1615,458.1c-22-55.1-0.7-121.5,51.7-184.3s135.7-122,237.6-162.8s203.1-55.3,284.3-45.9
                        c81.2,9.4,142.5,42.7,164.5,97.8c22,55.1,0.7,121.5-51.7,184.3s-147.9,177.9-276.1,238.7c-133.8,21.1-24.7-14.2-234.7,35.2
                        C1709.3,611.7,1637,513.2,1615,458.1z"
                    />
                </g>
            </mask>
            <path
                className={styles.st2}
                d="M2277.5,186.6c-124.2,18.1-263.9,37.3-265,36.4c-0.6-0.5-1-2.3-0.7-4c0.8-5.5-7.2-11.4-11.9-8.9
                c-1.7,0.9-2.4,0.9-2.7,0.1c-0.2-0.6-1.2-2.1-2.1-3.3c-2.4-3-11.3-3.9-14-1.5c-1.5,1.4-3,1.6-7.9,1c-5.2-0.6-6.5-0.4-9.6,1.5
                c-2,1.2-3.6,3-3.6,4c0,2-4.2,4.3-6.3,3.5c-0.8-0.3-3.2,1.5-5.5,4c-3.2,3.4-4.2,5.3-4.2,7.9c0,6.4,0.3,6.4-50.3,13.7
                c-25.4,3.7-47,7-48,7.4c-2.4,1-3.1,3.8-2.3,10.4c0.4,3.1,0,42.1-0.9,86.7c-0.8,44.5-1.8,99-2.1,121c-0.3,22-0.8,44.2-1.1,49.3
                c-0.4,6.2-0.2,9.3,0.5,9.3c0.6,0,5.3,2,10.4,4.5c9.2,4.4,9.4,4.5,12,10.3c1.5,3.4,3.3,10.2,4.2,16.3c1.7,11.6,3.1,14.5,8,16.5
                c1.8,0.8,3.6,1.8,3.9,2.2c0.3,0.5,5,0.9,10.4,0.9l9.9,0.1l0.7-13.6c0.4-7.5,0.7-18.4,0.7-24.3v-10.7l12.3-0.6
                c6.7-0.3,26.1-1.1,43.1-1.6l30.8-1l5,2.7c6.9,3.8,9.7,10.5,11.4,27.8c0.9,9.4,2.9,13.8,7.6,16.8c3.7,2.4,4.9,2.7,13.8,2.7h9.8
                l0.7-25.2c0.4-13.9,1.1-25.5,1.6-25.8c0.5-0.3,25.1-1.4,54.6-2.3l53.7-1.7l4.3,3.3c5.2,4,7.1,9.6,8.6,26.1c1,11.3,3.2,19.6,5.2,19.6
                c0.6,0,1.8,0.6,2.7,1.4c1,0.9,5.4,1.6,11.7,2l10.1,0.6l0.7-27c0.7-26.5,0.8-27,2.9-27.4c1.2-0.2,28.1-1.2,59.9-2.1l57.8-1.7l3.4,3.3
                c3,2.9,3.6,4.3,4.9,12.4c0.8,5,1.8,14,2.1,20c0.6,11.2,1.3,13.6,4.9,17.2c1.8,1.8,3.6,2.2,11.8,2.5l9.7,0.4l0.6-13
                c0.6-12,3.7-106.4,8.5-258.5c1.1-33.8,2.3-67.8,2.6-75.6l0.6-14.1l-15.6-14.2c-10.9-9.9-16.3-14.2-17.9-14.1
                C2322.5,180.2,2301.7,183.1,2277.5,186.6"
                onClick={() => {
                    props.onClick?.(CREWS_CATEGORY);
                }}
                onMouseMove={() => {
                    props.onMouseMove?.(true, CREWS_CATEGORY);
                }}
                onMouseLeave={() => {
                    props.onMouseLeave?.(false, CREWS_CATEGORY);
                }}
                onMouseEnter={() => {
                    props.onMouseEnter?.(false, CREWS_CATEGORY);
                }}
                ref={(_ref) => {
                    ref.current = _ref;
                }}
            />
        </React.Fragment>
    );
});

export default CrewsSVG;
