import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import Profile from '~/Components/Profile/Profile';
import ProgressCollected from '~/Components/Profile/ProgressCollected';
import { IInventoryState, IResponse } from '~/interfaces';

interface IState {
    inventory: IInventoryState;
    response: IResponse;
    isAuthorizedUser: boolean;
}

const stateSelector = (state: State): IState => {
    return {
        response: state.ReducerApp.response,
        inventory: state.ReducerApp.inventory,
        isAuthorizedUser: state.ReducerApp.isAuthorizedUser,
    };
};

const ContainerProfile = () => {
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const progressCollected = new ProgressCollected(appState.response, appState.inventory);

    return (
        <Profile
            response={appState.response}
            inventory={appState.inventory}
            isAuthorizedUser={appState.isAuthorizedUser}
            totalCount={progressCollected.getTotalCount()}
            availableCount={appState.isAuthorizedUser ? progressCollected.getAvailableCount() : 0}
        />
    );
};

export default ContainerProfile;
