import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    ensigns (lang: "{{ languageCode }}", isCatalogue: true) {
        id
        title
        description
        tags
        categoryType
        icons {
            default
            small
            large
        }
    }
}
`;
