import * as React from 'react';
import classNames from 'classnames';
import styles from '~/Components/Filter/Filter.scss';
import { FILTERS_NAMES, getCheckboxFiltersByCategory, IFilterValues, LEVEL_SHIP_NAME_FILTER, NATION_NAME_FILTER, TYPE_SHIP_NAME_FILTER } from '~/Components/Filter/filtersMap';
import { ICategories } from '~/interfaces';
import { DefaultFilter, FilterLevels, FilterShipTypes, NationsFilters } from '~/Components/Filter/utils';

interface IFilterContent {
    category: ICategories;
    filteredItems: any[];
}

const renderFilterByName = (name: FILTERS_NAMES, values: IFilterValues[], category: ICategories) => {
    switch (name) {
        case NATION_NAME_FILTER:
            return <NationsFilters category={category} values={values} name={name} />;

        case LEVEL_SHIP_NAME_FILTER:
            return <FilterLevels category={category} values={values} name={name} />;

        case TYPE_SHIP_NAME_FILTER:
            return <FilterShipTypes category={category} values={values} name={name} />;

        default:
            return <DefaultFilter category={category} values={values} name={name} />;
    }
};

const FilterCategory = (props: IFilterContent) => {
    const filters = getCheckboxFiltersByCategory(props.category);

    return (
        <React.Fragment>
            {filters.map((filter) => {
                return (
                    <div className={styles.filterColumn} key={filter.name}>
                        <div className={styles.filterColumnHeader}>{filter.title}</div>
                        <div
                            className={classNames(styles.filterColumnBody, {
                                [styles.width175]: filter.name === NATION_NAME_FILTER || filter.name === LEVEL_SHIP_NAME_FILTER,
                                [styles.filterColumnBodyLevel]: filter.name === LEVEL_SHIP_NAME_FILTER,
                            })}
                        >
                            {renderFilterByName(filter.name, filter.values, props.category)}
                        </div>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default FilterCategory;
