import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    crews (lang: "{{ languageCode }}", isCatalogue: true) {
        id
        title
        isUnique
        category
        type
        tags
        hasSampleVo  
        icons {
            minLevel
            maxLevel
            default
        }
        peculiarity {
            name
            title
        }
        nation {
            name
            title
            icons {
                default
                small
                large
                tiny
            }
        }
    }
}
`;
