export const settings = window.catalogue.settings;
export const currentRealm = settings.realm;
export const account = window.catalogue.state.account;
export const progressCollectedSteps = settings.progressCollectedSteps || [3, 6, 10, 15, 20, 30, 40, 55, 75, 100];
export const categoriesSettings = settings.categories;
export const isInGameBrowser = window.navigator.userAgent.indexOf(settings.ingameUserAgent) !== -1 && settings.isNeedToLoadImageFromLocalPath;
export const imagesRoomLocalPath = settings.imageRoomLocalPath;
export const localStorageKeyFirstShown = 'isShownPresentation';
export const localStorageKeyShownWelcomePage = 'isShownWelcomePage';
export const getLocalPath = (path: string) => `${settings.wowsLocalPath}${path}`;

categoriesSettings['dogTagComponents'] = categoriesSettings['dolls']; // backward compatibility with backend

export const getLocalKeyForWelcomePageByProfileId = (profileId: number) => `${localStorageKeyShownWelcomePage}_${profileId}`;
export const setLocalKeyForWelcomePageByProfileId = (profileId: number) => localStorage.setItem(getLocalKeyForWelcomePageByProfileId(profileId), 'true');
export const isNeedToShownWelcomePage = (profileId: number) => !localStorage.getItem(getLocalKeyForWelcomePageByProfileId(profileId));

export const singleton = function (constructor: any) {
    let instance: any;
    return function (data: any) {
        return instance ? instance : (instance = new constructor(data)) && instance;
    };
};

export const getArrayFromObjectByKey = (array: any[], key: string) => {
    return array.reduce((state, item) => {
        const keys = key.split('.');
        const value = keys.reduce((data: any, i) => {
            // @ts-ignore
            return Object.prototype.toString(data[i]) === '[object Object]' ? (data = data[i]) && data : data[i];
        }, item);

        return state.push(value) && state;
    }, []);
};
