import { ACTION_TYPE_CHANGE_FILTER_CATEGORY, ACTION_RESET_SEARCH, ACTION_SEARCH, ACTION_HIDE_DIALOG, ACTION_DIALOG_SHOW } from '~/Actions/ActionCategory';

import { ActionType } from '~/Actions';
import { State } from '~/Reducers/index';
import { IDialogData } from '~/interfaces';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';

export type CategoryState = {
    search?: any;
    dialog?: IDialogData;
};

const initialState: CategoryState = {
    search: null,
};

export type IReducerCategory = (state: CategoryState, action: ActionType) => State;
export const ReducerCategory = (state: CategoryState = initialState, action: ActionType) => {
    switch (action.type) {
        case ACTION_TYPE_CHANGE_FILTER_CATEGORY:
            const filteredData = action.filteredData || {};

            return Object.assign({}, state, {
                ...filteredData,
            });

        case ACTION_SEARCH:
            return {
                ...state,
                search: {
                    results: [...action.result],
                },
            };

        case ACTION_RESET_SEARCH:
            return {
                ...state,
                search: null,
            };

        case ACTION_DIALOG_SHOW:
            updateBrowserControlState(true);
            return {
                ...state,
                dialog: {
                    ...action.dialogData,
                },
            };

        case ACTION_HIDE_DIALOG:
            updateBrowserControlState(false);
            return {
                ...state,
                dialog: null,
            };

        default:
            return state;
    }
};
