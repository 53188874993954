import { ReducerApp, AppState } from './ReducerApp';
import { ReducerCategory, CategoryState } from './ReducerCategory';
import { ReducerFilter, FilterState } from './ReducerFilter';
import { combineReducers } from 'redux';

const reducers = {
    ReducerApp,
    ReducerCategory,
    ReducerFilter,
};

export interface State {
    ReducerApp: AppState;
    ReducerCategory: CategoryState;
    ReducerFilter: FilterState;
}

export default combineReducers(reducers);
