import styles from './ProgressBar.scss';
import { ICategories } from '~/interfaces';
import { isAuthorizedUser } from '~/config/user';
import { DivTooltip, ProgressBar as ProgressBarUIKit } from '@wg/wows-react-uikit';
import { filterItemsByUniqueType } from '~/Actions/ActionFilter';
import { t } from '~/helpers/localization';
import DefaultTooltip from '~/Components/Tooltip/DefaultTooltip';
import { UNIQUE_TYPES } from '~/Actions/ActionFilterTypes';
import { useDispatch } from 'react-redux';
import { COMMON_TYPES, LEGENDARY_TYPE, UNIQUE_TYPE } from '~/settings/filters';
import { playTabClickSound } from '~/helpers/audioApi';
import dwhExport from '~/helpers/dwhExport';
import { DWH_EVENTS } from '~/helpers/consts';
import { resetSearch } from '~/Actions/ActionCategory';

interface IProgressBar {
    category: ICategories;
    availableAwardsCount: number;
    totalCount: number;
    uniqueAwardsCount: number;
    rareAwardsCount: number;
}

const ProgressBar = (props: IProgressBar) => {
    const dispatch = useDispatch();
    const _isAuthorizedUser = isAuthorizedUser();

    const getProgressPercent = () => {
        const { availableAwardsCount, totalCount } = props;

        if (totalCount === 0) {
            return totalCount;
        }

        return Math.ceil((availableAwardsCount * 100) / totalCount) / 100;
    };

    const changeTypeDisplayFilter = (type: UNIQUE_TYPES) => {
        playTabClickSound();
        dwhExport.send(DWH_EVENTS.APPLY_PRESET, { category: props.category, preset_name: type.toLowerCase() });
        dispatch(resetSearch());
        dispatch(filterItemsByUniqueType(props.category, type));
    };

    return (
        <div className={styles.progressWrap}>
            <div className={styles.progressCollectedInfo}>
                <div className={styles.text}>{_isAuthorizedUser ? t('Собрано:') : t('Всего:')}</div>
                <div className={styles.collectedInfo}>
                    {_isAuthorizedUser ? (
                        <span className={styles.cursor} onClick={changeTypeDisplayFilter.bind(this, COMMON_TYPES)}>
                            {props.availableAwardsCount}/{props.totalCount}
                        </span>
                    ) : props.totalCount}
                    {!!parseInt(props.rareAwardsCount.toString(), 10) && (
                        <span className={styles.exclusiveCounterWrap} onClick={changeTypeDisplayFilter.bind(this, LEGENDARY_TYPE)}>
                            <DivTooltip
                                tooltipBody={
                                    <DefaultTooltip
                                        text={t('Категория "Редкие" включает предметы, которые очень сложно, либо уже невозможно получить. Не учитываются в процессе наполнения коллекции.')}
                                        footer={t('Показать')}
                                    />
                                }
                            >
                                <span className={styles.gold}>
                                    &nbsp;<span className={styles.plus}>+</span> {t('Редкие:')} {props.rareAwardsCount}
                                </span>
                            </DivTooltip>
                        </span>
                    )}
                    {!!parseInt(props.uniqueAwardsCount.toString(), 10) && (
                        <span className={styles.exclusiveCounterWrap} onClick={changeTypeDisplayFilter.bind(this, UNIQUE_TYPE)}>
                            <DivTooltip
                                tooltipBody={
                                    <DefaultTooltip
                                        text={t('Категория "Дополнительные" включает необычные или авторские предметы, которые не учитываются в прогрессе сбора коллекции.')}
                                        footer={t('Показать')}
                                    />
                                }
                            >
                                <span className={styles.unique}>
                                    &nbsp;<span className={styles.plus}>+</span> {t('Дополнительные:')} {props.uniqueAwardsCount}
                                </span>
                            </DivTooltip>
                        </span>
                    )}
                </div>
            </div>
            <ProgressBarUIKit size={'large'} completed={getProgressPercent()} color={'#ffcc65'} />
        </div>
    );
};

export default ProgressBar;
