import { categoriesSettings } from '~/helpers/utils';
import { ICategoriesSettings, Item } from '~/interfaces';
import { CREWS_CATEGORY, DOLLS_CATEGORY, ENSIGNS_CATEGORY, PERMOFLAGES_CATEGORY, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';
import { isRareItem, isUniqueItem } from '~/helpers/consts';
import { isAnime } from '~/helpers/vehiclesApi';

class ResponseItemsMap {
    public getCategorySettings(category: string): ICategoriesSettings {
        return categoriesSettings[category];
    }

    public isLegendaryItem(category: string, item: Item) {
        const legendaryItems = this.getCategorySettings(category).legendary || [];
        const uniqueItems = this.getCategorySettings(category).hiddenIfMissing || [];

        return (legendaryItems.includes(item.id) || isRareItem(item)) && !uniqueItems.includes(item.id);
    }

    public isUniqueItem(category: string, item: Item) {
        const uniqueItems = this.getCategorySettings(category).hiddenIfMissing || [];

        return uniqueItems.includes(item.id) || isUniqueItem(item) || isAnime(item);
    }

    public getHardcodeAnimeShipIds() {
        return ['3552458448', '3541972688', '3542005744', '3554588464', '3552458448'];
    }

    public getExcludedShipIds() {
        return this.getCategorySettings(VEHICLE_CATEGORY).hidden || [];
    }

    public getLegendaryShipIds() {
        return this.getCategorySettings(VEHICLE_CATEGORY).legendary || [];
    }

    public getUniqueShipIds() {
        return this.getCategorySettings(VEHICLE_CATEGORY).hiddenIfMissing || [];
    }

    public getExcludedCrewIds() {
        return this.getCategorySettings(CREWS_CATEGORY).hidden || [];
    }

    public getLegendaryCrewIds() {
        return this.getCategorySettings(CREWS_CATEGORY).legendary || [];
    }

    public getUniqueCrewIds() {
        return this.getCategorySettings(CREWS_CATEGORY).hiddenIfMissing || [];
    }

    public getExcludedPermoflagesIds() {
        return this.getCategorySettings(PERMOFLAGES_CATEGORY).hidden || [];
    }

    public getLegendaryPermoflagesIds() {
        return this.getCategorySettings(PERMOFLAGES_CATEGORY).legendary || [];
    }

    public getUniquePermoflagesIds() {
        return this.getCategorySettings(PERMOFLAGES_CATEGORY).hiddenIfMissing || [];
    }

    public getExcludedEnsignsIds() {
        return this.getCategorySettings(ENSIGNS_CATEGORY).hidden || [];
    }

    public getLegendaryEnsignsIds() {
        return this.getCategorySettings(ENSIGNS_CATEGORY).legendary || [];
    }

    public getUniqueEnsignsIds() {
        return this.getCategorySettings(ENSIGNS_CATEGORY).hiddenIfMissing || [];
    }

    public getExcludedDollsIds() {
        return this.getCategorySettings(DOLLS_CATEGORY).hidden || [];
    }

    public getLegendaryDollsIds() {
        return this.getCategorySettings(DOLLS_CATEGORY).legendary || [];
    }

    public getUniqueDollsIds() {
        return this.getCategorySettings(DOLLS_CATEGORY).hiddenIfMissing || [];
    }
}

export default new ResponseItemsMap();
