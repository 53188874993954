import * as React from 'react';
import * as Redux from 'redux';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducer from './Reducers';
import { createLogger } from 'redux-logger';
import ContainerApp from './Containers/ContainerApp';
import { Router } from 'react-router-dom';
import { history } from './helpers/history';
import wowsEntities from '@wg/wows-entities';

import './styles/global.scss';
import '@wg/wows-css-uikit/index.css';
import '@wg/wows-entities/index.css';
import { settings } from './helpers/utils';
import { TooltipProvider } from '@wg/wows-react-uikit';

const middleware: Redux.Middleware[] = [thunk];

if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
}

export const store = createStore(reducer, applyMiddleware(...middleware));

const App = () => {
    React.useEffect(() => {
        wowsEntities.init({
            vortexBaseUrl: window.catalogue.urls.vortex,
            languageCode: settings.languageCode,
        })
    }, []);

    return (
        <Provider store={store}>
            <Router history={history}>
                <TooltipProvider>
                    <ContainerApp />
                </TooltipProvider>
            </Router>
        </Provider>
    );
};

export default App;
