import { SUPERSHIP_ICON } from '~/helpers/consts';

export const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const getLevelFromRomanFormat = (level: number) => {
    switch (level) {
        case 1:
            return 'I';
        case 2:
            return 'II';
        case 3:
            return 'III';
        case 4:
            return 'IV';
        case 5:
            return 'V';
        case 6:
            return 'VI';
        case 7:
            return 'VII';
        case 8:
            return 'VIII';
        case 9:
            return 'IX';
        case 10:
            return 'X';
        case 11:
            return SUPERSHIP_ICON;
        default:
            break;
    }
};
