import { t } from '~/helpers/localization';

export type GLOBAL_FILTERS_NAMES = 'author_content';

interface IMaps {
    name: GLOBAL_FILTERS_NAMES;
    title: string;
}

const globalFiltersMap: IMaps[] = [
    {
        name: 'author_content',
        title: t('Показать авторские предметы'),
    },
];

export default globalFiltersMap;
