export const intersection = (array1: any[], array2: any[]) => {
    return array1.filter((item) => array2.includes(item));
};

export const diff = (array1: any[], array2: any[]) => {
    return array1.filter((x) => !array2.includes(x)).concat(array2.filter((x) => !array1.includes(x)));
};

export const getDiapasonOfNumbers = (numbers: number[], delimiter = '-') => {
    numbers = numbers.sort((a, b) => (b > a ? -1 : 1));
    let memory: any = null;

    return numbers.reduce((result, number, index) => {
        if (number + 1 === numbers[++index]) {
            memory === null && (memory = number);
        } else {
            result.push(memory || memory === 0 ? `${memory}${delimiter}${number}` : number);
            memory = null;
        }

        return result;
    }, []);
};

export const flat = function (arr: any[]): any[] {
    const array: any[] = [];
    const stack = [arr];

    while (stack.length > 0) {
        const item = stack.pop();

        if (!Array.isArray(item)) {
            array.push(item);
        } else {
            item.forEach((i) => {
                stack.push(i);
            });
        }
    }

    return array.reverse();
};

export const getArrayFromObject = (object: any) =>
    object.reduce((state: number[], item: any) => {
        state.push(item.id);

        return state;
    }, []);
