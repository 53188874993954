const parseQueryTemplate = (tpl: string) => {
    const languageCode = window.catalogue.settings.languageCode;

    return tpl.split('{{ languageCode }}').join(languageCode || 'en');
};

export function tpl(templateData: any) {
    return parseQueryTemplate(
        templateData[0]
            .replace(/\s{2,}/g, ' ')
            .split(/\n/)
            .join(' ')
            .trim(),
    );
}
