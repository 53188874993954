import { Ensign } from '~/vortex';
import { _Item } from '~/interfaces';
import ResponseItemMap from './index';
import { ENSIGNS_CATEGORY } from '~/Actions/ActionCategory';

export const prepareEnsigns = (responseEnsigns: Ensign[]): Ensign[] => {
    const rareEnsigns: Ensign[] = [];
    const ensigns = responseEnsigns
        .map((item: Ensign & _Item) => {
            if (ResponseItemMap.isLegendaryItem(ENSIGNS_CATEGORY, item)) {
                item.isRareItem = true;
                rareEnsigns.push(item);
            } else if (ResponseItemMap.isUniqueItem(ENSIGNS_CATEGORY, item)) {
                item.isUniqueItem = true;
            }

            return item;
        })
        .filter((item) => {
            if (ResponseItemMap.getExcludedEnsignsIds().includes(item.id)) {
                return false;
            }

            return !item.isRareItem;
        });

    return [...rareEnsigns, ...ensigns];
};
