import * as React from 'react';
import styles from './CategoryLayout.scss';
import { ICategories, Item as IItem } from '~/interfaces';
import { onClickByHeaderTitle, parseCategoryTitle } from '~/Layouts/Category/CategoryPreTitle';
import { t } from '~/helpers/localization';
import Item from '~/Components/Item/Item';
import ProgressBar from '~/Components/ProgressBar/ProgressBar';
import classNames from 'classnames';
import Search from '~/Components/Search/Search';
import AdditionalFilter from '~/Components/AdditionalFilter/AdditionalFilter';
import Filter from '~/Components/Filter/Filter';
import { playButtonClickSound, playButtonDialogClickSound } from '~/helpers/audioApi';
import useScroll from '~/hooks/useScroll';

interface ICategoryLayout {
    availableAwardsCount: number;
    totalCount: number;
    filteredTotalCount?: number;
    uniqueAwardsCount: number;
    rareAwardsCount: number;
    items: IItem[];
    inventoryItems: any;
    category: ICategories;
}

const CategoryLayout = (props: ICategoryLayout) => {
    const categoryBodyRef = React.useRef(null);
    const shadowsRef = React.useRef<HTMLDivElement>();
    const buttonToTop = React.useRef<HTMLDivElement>();

    const onScroll = (event: React.UIEvent) => {
        if (!shadowsRef.current) {
            return;
        }

        const target = event.target as HTMLDivElement;

        if (target.scrollTop > 10) {
            shadowsRef.current.classList.add(styles.visibleShadows);
        } else {
            shadowsRef.current.classList.remove(styles.visibleShadows);
        }

        if (target.scrollTop > 100) {
            buttonToTop.current.classList.add(styles.visibleScrollTop);
        } else {
            buttonToTop.current.classList.remove(styles.visibleScrollTop);
        }
    };

    useScroll(
        categoryBodyRef,
        () => {
            categoryBodyRef.current?.classList.add(styles.cursorDisabled);
        },
        () => {
            categoryBodyRef.current?.classList.remove(styles.cursorDisabled);
        },
    );

    const scrollToTop = () => {
        playButtonClickSound();

        if (categoryBodyRef.current) {
            // @ts-ignore
            categoryBodyRef.current.scrollTop = 0;
        }
    };

    return (
        <React.Fragment>
            <div className={styles.categoryHeader}>
                <div className={styles.categoryHeaderTitleWrapper}>
                    <div
                        className={styles.categoryHeaderTitle}
                        onClick={(event: React.MouseEvent) => {
                            playButtonDialogClickSound();
                            onClickByHeaderTitle(event, props.category);
                        }}
                        dangerouslySetInnerHTML={{ __html: parseCategoryTitle(props.category) }}
                    />
                </div>
                <ProgressBar
                    category={props.category}
                    availableAwardsCount={props.availableAwardsCount}
                    rareAwardsCount={props.rareAwardsCount}
                    totalCount={props.totalCount}
                    uniqueAwardsCount={props.uniqueAwardsCount}
                />
                <div className={styles.filterWrap}>
                    <Filter category={props.category} availableCount={props.availableAwardsCount} filteredAvailableCount={props.filteredTotalCount} filteredItems={props.items} />
                    <div className={styles.filterBaseLeftPanel}>
                        <Search category={props.category} onKeyDown={null} resetSearch={null} />
                        <AdditionalFilter category={props.category} />
                    </div>
                </div>
                <div ref={shadowsRef} className={classNames(styles.overflowShadows, styles.categoryBodyShadows)} />
            </div>
            <div 
                className={styles.categoryBody}
                onScroll={onScroll}
                ref={(_ref) => {
                    if (!_ref) {
                        return;
                    }
                    categoryBodyRef.current = _ref;
                }}
            >
                <div className={styles.categoryBodyContent}>
                    {props.items.length ? (
                        props.items.map((item, index) => {
                            return <Item parentRef={categoryBodyRef} key={`${props.category}_${item.id}_${index}_${props.items.length}`} category={props.category} item={item} />;
                        })
                    ) : (
                        <div className={styles.filterNotFound}>{t('Ничего не найдено')}</div>
                    )}
                </div>
            </div>
            <div className={styles.categoryWrap}>
                <div className={styles.categoryWrapper}>
                    <div className={styles.categoryWrapperContent}>
                        <div className={styles.buttonToTop} ref={buttonToTop} onClick={scrollToTop} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CategoryLayout;
